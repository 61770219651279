import styles from "../Icon.module.css";

const Clip = ({ color = "#FFFFFF" }) => (
  <svg
    className={`icon ${styles.clip}`}
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.4228 1.33261e-06C12.9412 1.33261e-06 11.4596 0.562309 10.3349 1.68698L0.974906 11.047C0.7133 11.2982 0.60792 11.6712 0.699409 12.0221C0.790897 12.373 1.06496 12.6471 1.4159 12.7386C1.76683 12.8301 2.13981 12.7247 2.39099 12.4631L11.751 3.10306C13.235 1.61908 15.6106 1.61908 17.0946 3.10306C18.5786 4.58705 18.5786 6.96263 17.0946 8.44662L8.0739 17.4673C7.35057 18.1906 6.2076 18.1906 5.48427 17.4673C4.76094 16.744 4.76094 15.601 5.48427 14.8777L12.6664 7.69555C12.928 7.44437 13.0334 7.07139 12.9419 6.72045C12.8504 6.36951 12.5763 6.09545 12.2254 6.00396C11.8745 5.91247 11.5015 6.01785 11.2503 6.27946L4.06819 13.4616C2.57936 14.9504 2.57936 17.3946 4.06819 18.8834C5.55701 20.3722 8.00116 20.3722 9.48998 18.8834L18.5107 9.8627C20.76 7.61336 20.76 3.93632 18.5107 1.68698C17.386 0.562306 15.9044 0 14.4228 0L14.4228 1.33261e-06Z'
      fill={color}
    />
  </svg>
);

export default Clip;

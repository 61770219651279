import styles from "./Button.module.css";

import React from "react";

import Icon from "../Icon";

const Button = ({
  color = "blue",
  size = "default",
  template,
  type = "functional",
  buttonType = "button",
  loading = false,
  children,
  onClick,
  href,
  disabled,
  icon,
  iconColor,
  additiobalStyles = {},
  additiobalClassnames = ""
}) => {
  const renderIcon = () => {
    if (icon) {
      return (
        <span className={styles.sectionIcon}>
          <Icon color={iconColor} name={icon} />
        </span>
      );
    }
  };

  const renderContent = () => {
    if (children) {
      return <span className={`${styles.section} ${styles.sectionContent}`}>{children}</span>;
    }
  };

  const renderLoader = () => {
    if (loading) {
      return (
        <span className={`${styles.section} ${styles.sectionLoader}`}>
          <span className={styles.loader} />
        </span>
      );
    }
  };

  const params = {
    className: `${styles.component} ${size ? styles[size] : ""} ${color ? styles[color] : ""} ${
      template ? styles[template] : ""
    } ${additiobalClassnames}`,
    disabled
  };

  switch (type) {
    case "internal":
      return (
        <a href={href} {...params} style={additiobalStyles}>
          {renderIcon()}
          {renderContent()}
          {renderLoader()}
        </a>
      );
    case "external":
      return (
        <a href={href} {...params} style={additiobalStyles}>
          {renderIcon()}
          {renderContent()}
          {renderLoader()}
        </a>
      );
    case "functional":
    default:
      return (
        <button type={buttonType} onClick={onClick} {...params} style={additiobalStyles}>
          {renderIcon()}
          {renderContent()}
          {renderLoader()}
        </button>
      );
  }
};

export default Button;

import './styles/variables.css';
import './styles/global.scss';
import './styles/exportVariables.scss';
import './styles/blockSpacingStyles.scss';

import React from 'react';
import isWindowDefined from './helpers/isWindowDefined';
import { AuthorizationContextProvider } from './bootstrap/contexts/AuthorizationContext';
import LandingForm from './components/widgets/LandingForm';

const App = ({injectTo}) => {
  const instructionId = isWindowDefined() 
    && window.destraParams 
    && window.destraParams.instructionId 
  ? window.destraParams.instructionId 
  : injectTo.getAttribute("instruction-id");

  return (
    <AuthorizationContextProvider>
      <LandingForm instructionId={instructionId}/>
    </AuthorizationContextProvider>
  ) 
}
export default App;

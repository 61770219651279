import styles from "../Icon.module.css";

import React from "react";

const Hat = ({color='#FFFFFF'}) => (
  <svg className={`icon ${styles.hat}`} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M20 7.16126V12.9933C20 13.1869 19.9231 13.3727 19.7861 13.5096C19.6492 13.6466 19.4634 13.7235 19.2697 13.7235C19.0761 13.7235 18.8903 13.6466 18.7534 13.5096C18.6164 13.3727 18.5395 13.1869 18.5395 12.9933V7.2713L9.99654 12.153C9.9391 12.1874 9.87342 12.2055 9.80648 12.2055C9.73954 12.2055 9.67385 12.1874 9.61641 12.153L0.18315 6.76112C0.127245 6.72725 0.0810198 6.67953 0.0489375 6.62258C0.0168551 6.56563 0 6.50137 0 6.43601C0 6.37064 0.0168551 6.30638 0.0489375 6.24943C0.0810198 6.19248 0.127245 6.14477 0.18315 6.1109L9.61641 0.719032C9.67385 0.684658 9.73954 0.666504 9.80648 0.666504C9.87342 0.666504 9.9391 0.684658 9.99654 0.719032L19.3698 6.08089C19.5611 6.189 19.7203 6.346 19.831 6.53583C19.9418 6.72565 20.0001 6.94149 20 7.16126ZM16.2187 10.2723L10.0966 13.7735C10.0095 13.8276 9.90899 13.8562 9.80648 13.8562C9.70396 13.8562 9.60348 13.8276 9.51638 13.7735L3.38426 10.2723C3.29576 10.2236 3.19606 10.1987 3.09503 10.2003C2.99399 10.2019 2.89512 10.2299 2.80821 10.2814C2.72129 10.3329 2.64935 10.4063 2.59949 10.4942C2.54964 10.5821 2.5236 10.6815 2.52396 10.7825V13.3934C2.52505 13.4922 2.55135 13.5891 2.60037 13.6749C2.64939 13.7606 2.7195 13.8325 2.80406 13.8836L9.50637 17.885C9.59727 17.9386 9.70091 17.967 9.80648 17.967C9.91204 17.967 10.0157 17.9386 10.1066 17.885L16.8089 13.8836C16.8935 13.8325 16.9636 13.7606 17.0126 13.6749C17.0616 13.5891 17.0879 13.4922 17.089 13.3934V10.7825C17.0904 10.68 17.0645 10.579 17.0142 10.4897C16.9638 10.4004 16.8907 10.3261 16.8023 10.2742C16.7138 10.2224 16.6132 10.1949 16.5107 10.1946C16.4082 10.1942 16.3075 10.2211 16.2187 10.2723Z' fill={color} />
  </svg>
);

export default Hat;

import styles from "../Icon.module.css";

import React from "react";

const DocumentWithPenInCirlce = ({color="#4D92DC"}) => (
  <svg className={`icon ${styles.documentWithPenInCirlce}`} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="59" height="59" rx="29.5" fill="white"/>
    <path d="M22.3115 17.5C20.7615 17.5 19.499 18.7625 19.499 20.3125V39.6875C19.499 41.2375 20.7615 42.5 22.3115 42.5H36.6865C38.2365 42.5 39.499 41.2375 39.499 39.6875V32.9309L36.1799 36.2561C35.7987 36.6311 35.324 36.9189 34.8115 37.0752L31.7048 38.031C31.4923 38.0935 31.274 38.125 31.0615 38.125C30.4928 38.125 29.9298 37.9004 29.5173 37.4817C29.3361 37.3067 29.1927 37.1 29.0864 36.875H25.4304C24.9117 36.875 24.4929 36.4563 24.4929 35.9375C24.4929 35.4625 24.8484 35.0686 25.3047 35.0061L25.4304 35H29.0547L29.8237 32.5H25.4365C24.9178 32.5 24.499 32.0813 24.499 31.5625C24.499 31.0438 24.9178 30.625 25.4365 30.625H30.937L34.0681 27.5H32.3115C30.7615 27.5 29.499 26.2375 29.499 24.6875V17.5H22.3115ZM31.374 18.0505V24.6875C31.374 25.2063 31.7928 25.625 32.3115 25.625H35.9431L37.2737 24.2932C37.3299 24.2307 37.3931 24.1746 37.4556 24.1309L31.374 18.0505ZM41.9746 23.7378C41.6499 23.7475 41.3416 23.8832 41.1152 24.1162L40.7527 24.4788C39.8626 24.2294 38.8622 24.472 38.1587 25.1746L31.6279 31.7053C31.3923 31.941 31.2182 32.2326 31.1213 32.5488L30.1655 35.6628C30.0637 35.9947 30.1531 36.3547 30.3987 36.6003C30.5774 36.7791 30.8165 36.875 31.0615 36.875C31.1534 36.875 31.2462 36.8616 31.3362 36.8335L34.449 35.8789C34.7671 35.782 35.0581 35.6061 35.2937 35.3711L41.8696 28.7964C42.4184 28.2476 42.6904 27.4894 42.6167 26.7163C42.6012 26.5543 42.5672 26.3975 42.5227 26.2439L42.8828 25.8838C43.2525 25.5244 43.3637 24.975 43.1627 24.5002C42.9618 24.0253 42.49 23.7226 41.9746 23.7378V23.7378Z" fill={color}/>
  </svg>
);

export default DocumentWithPenInCirlce;
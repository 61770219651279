const Shape = ({ color = "#4D92DC" }) => (
  <svg
    className='icon icon--search'
    viewBox='0 0 31 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M22.461 12.538a1.225 1.225 0 0 0 0-1.826 1.475 1.475 0 0 0-1.973 0l-7.383 6.836-3.199-2.961a1.475 1.475 0 0 0-1.973 0 1.225 1.225 0 0 0 0 1.826l4.185 3.875a1.475 1.475 0 0 0 1.973 0l8.37-7.75ZM15.49.38a1.475 1.475 0 0 0-1.973 0C10.047 3.588 6.21 5.166 1.945 5.166c-.77 0-1.395.578-1.395 1.291v7.754c0 8.314 4.579 13.966 13.509 16.722a1.5 1.5 0 0 0 .882 0c8.93-2.756 13.509-8.408 13.509-16.722V6.458c0-.713-.625-1.291-1.395-1.291-4.272 0-8.106-1.578-11.566-4.788ZM3.34 7.701c4.128-.29 7.852-1.876 11.162-4.629 3.306 2.754 7.028 4.34 11.159 4.63v6.51c0 3.703-1.012 6.624-2.85 8.897-1.778 2.2-4.487 3.986-8.31 5.234-3.824-1.248-6.533-3.035-8.31-5.234-1.838-2.273-2.85-5.194-2.85-8.897v-6.51Z'
      fill={color}
    />
  </svg>
);

export default Shape;

import React from "react";

const Consult = () => (
  <svg className='icon' viewBox='0 0 30 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M26.7859 0H3.21431C1.43909 0 0 1.43909 0 3.21431V20.3573C0 22.1325 1.43909 23.5716 3.21431 23.5716H7.01793L6.43509 28.8109C6.37011 29.3991 6.79425 29.9285 7.38243 29.9935C7.68616 30.0271 7.98976 29.9293 8.21689 29.7249L15.0548 23.5716H26.7859C28.5612 23.5716 30.0002 22.1325 30.0002 20.3573V3.21431C30.0002 1.43909 28.5612 0 26.7859 0Z'
      fill='#6B4095'
    />
  </svg>
);

export default Consult;

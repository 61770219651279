import styles from "../Icon.module.css";

const Instagram = ({ color = "#ffffff" }) => (
  <svg
    className={`icon ${styles.instagram}`}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.4806 0H5.51916C2.4759 0 0 2.47601 0 5.51928V14.4807C0 17.5241 2.4759 20 5.51916 20H14.4806C17.5241 20 20 17.524 20 14.4807V5.51928C20.0001 2.47601 17.5241 0 14.4806 0ZM9.99968 4.8468C7.15801 4.8468 4.84619 7.15862 4.84619 10.0003C4.84619 12.8419 7.15801 15.1536 9.99968 15.1536C12.8414 15.1536 15.1532 12.8419 15.1532 10.0003C15.1532 7.15862 12.8414 4.8468 9.99968 4.8468ZM9.99968 13.3789C8.13658 13.3789 6.62069 11.8633 6.62069 10.0002C6.62069 8.13696 8.13646 6.62118 9.99968 6.62118C11.8629 6.62118 13.3787 8.13696 13.3787 10.0002C13.3787 11.8633 11.8628 13.3789 9.99968 13.3789ZM14.4498 3.72382C14.6912 3.48131 15.0271 3.3429 15.369 3.3429C15.712 3.3429 16.048 3.48131 16.2893 3.72382C16.5318 3.96515 16.6702 4.30112 16.6702 4.64419C16.6702 4.98608 16.5318 5.32205 16.2893 5.56457C16.0468 5.8059 15.712 5.94549 15.369 5.94549C15.0271 5.94549 14.6911 5.8059 14.4498 5.56457C14.2072 5.32205 14.0677 4.9862 14.0677 4.64419C14.0677 4.30112 14.2071 3.96515 14.4498 3.72382Z'
      fill={color}
    />
  </svg>
);

export default Instagram;

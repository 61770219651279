export default ({ color = "#637CAC" }) => (
  <svg
    className='icon icon--coins'
    viewBox='0 0 30 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.71523 16.1311C9.55831 16.1311 10.3732 16.0767 11.1444 15.9753V7.52294C10.3733 7.42164 9.55831 7.36719 8.71523 7.36719C3.90141 7.36719 -0.000976562 9.13708 -0.000976562 11.3204V12.1778C-0.000976562 14.3611 3.90141 16.1311 8.71523 16.1311Z'
      fill='#637CAC'
    />
    <path
      d='M8.71523 20.5446C9.55831 20.5446 10.3732 20.4901 11.1444 20.3889V17.9113C10.3731 18.0126 9.55854 18.0678 8.71523 18.0678C5.07203 18.0678 1.95113 17.054 0.648627 15.6145C0.428323 15.371 -0.000976562 15.4056 -0.000976562 15.7339V16.5913C-0.000976562 18.7747 3.90141 20.5446 8.71523 20.5446Z'
      fill={color}
    />
    <path
      d='M11.1453 22.5176V22.325C10.3741 22.4263 9.55951 22.4815 8.71621 22.4815C5.07296 22.4815 1.95203 21.4677 0.649557 20.0281C0.429258 19.7846 0 19.8192 0 20.1476V21.0049C0 23.1884 3.90239 24.9582 8.71621 24.9582C9.67888 24.9582 10.6048 24.8873 11.4707 24.7566C11.2561 24.3175 11.1453 23.8545 11.1453 23.3749V22.5176Z'
      fill={color}
    />
    <path
      d='M21.2846 0.671692C16.4708 0.671692 12.5684 2.44159 12.5684 4.62495V5.4823C12.5684 7.66567 16.4708 9.43556 21.2846 9.43556C26.0983 9.43556 30.0008 7.66567 30.0008 5.4823V4.62495C30.0008 2.44159 26.0983 0.671692 21.2846 0.671692Z'
      fill={color}
    />
    <path
      d='M21.2846 11.3724C17.6414 11.3724 14.5204 10.3586 13.218 8.91906C12.9976 8.67556 12.5684 8.71013 12.5684 9.03851V9.89585C12.5684 12.0792 16.4708 13.8491 21.2846 13.8491C26.0983 13.8491 30.0008 12.0792 30.0008 9.89585V9.03851C30.0008 8.71013 29.5715 8.67556 29.3512 8.91906C28.0487 10.3586 24.9278 11.3724 21.2846 11.3724Z'
      fill={color}
    />
    <path
      d='M21.2846 15.786C17.6414 15.786 14.5205 14.7722 13.218 13.3327C12.9976 13.0892 12.5684 13.1238 12.5684 13.4521V14.3095C12.5684 16.4929 16.4708 18.2628 21.2846 18.2628C26.0983 18.2628 30.0008 16.4929 30.0008 14.3095V13.4521C30.0008 13.1238 29.5715 13.0892 29.3512 13.3327C28.0487 14.7723 24.9278 15.786 21.2846 15.786Z'
      fill={color}
    />
    <path
      d='M21.2846 20.4379C17.6413 20.4379 14.5204 19.4241 13.2179 17.9845C12.9976 17.7411 12.5684 17.7757 12.5684 18.104V18.9614C12.5684 21.1448 16.4708 22.9147 21.2846 22.9147C26.0983 22.9147 30.0008 21.1448 30.0008 18.9614V18.104C30.0008 17.7756 29.5715 17.741 29.3512 17.9845C28.0487 19.424 24.9278 20.4379 21.2846 20.4379Z'
      fill={color}
    />
    <path
      d='M21.2846 24.8515C17.6413 24.8515 14.5204 23.8377 13.2179 22.3982C12.9976 22.1547 12.5684 22.1893 12.5684 22.5176V23.375C12.5684 25.5583 16.4708 27.3282 21.2846 27.3282C26.0983 27.3282 30.0008 25.5583 30.0008 23.375V22.5176C30.0008 22.1892 29.5716 22.1546 29.3512 22.3981C28.0488 23.8377 24.9278 24.8515 21.2846 24.8515Z'
      fill={color}
    />
  </svg>
);

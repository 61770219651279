import axios from "axios";
import decrypt from "../../helpers/decrypt";
import isWindowDefined from "../../helpers/isWindowDefined";

export const resultDecrypt = (data) => decrypt(data.result);

export default axios.create({
  headers: {
    "X-Gathering-Id": isWindowDefined() ? window.localStorage.gatheringId : ""
  }
});

import React from "react";
import styles from "./Title.module.css";

enum HeadingsType {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6"
}

interface Props {
  type: keyof typeof HeadingsType;
  className?: string;
  children?: React.ReactNode;
}

export default function Title({ type, children, className }: Props): React.ReactElement {
  const Component = type;
  return (
    <Component lang='ru' className={`${styles.component} ${className}`}>
      {children}
    </Component>
  );
}

import styles from "../Icon.module.css";

const Home = ({ color = "#FFFFFF" }) => (
  <svg
    className={`icon ${styles.home}`}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M29.1902 13.0486C29.1895 13.0479 29.1888 13.0472 29.1881 13.0465L16.9505 0.809326C16.4289 0.287476 15.7354 0 14.9977 0C14.26 0 13.5665 0.287247 13.0446 0.809097L0.813416 13.0401C0.809296 13.0442 0.805176 13.0486 0.801056 13.0527C-0.270111 14.13 -0.26828 15.878 0.80632 16.9526C1.29727 17.4438 1.94569 17.7283 2.63898 17.758C2.66713 17.7608 2.69551 17.7621 2.72412 17.7621H3.21187V26.768C3.21187 28.55 4.66184 30 6.44437 30H11.2321C11.7173 30 12.111 29.6066 12.111 29.1211V22.0605C12.111 21.2473 12.7725 20.5859 13.5857 20.5859H16.4097C17.2229 20.5859 17.8843 21.2473 17.8843 22.0605V29.1211C17.8843 29.6066 18.2778 30 18.7632 30H23.551C25.3335 30 26.7835 28.55 26.7835 26.768V17.7621H27.2358C27.9732 17.7621 28.6667 17.4749 29.1888 16.953C30.2646 15.8766 30.265 14.1257 29.1902 13.0486Z'
      fill={color}
    />
  </svg>
);

export default Home;

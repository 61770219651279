import styles from "../Icon.module.css";

import React from "react";

const TickInCircle = ({}) => (
  <svg className={`icon ${styles.tickInCircle}`} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z" fill="#1EC28C" fillOpacity="0.1"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M39.6028 22.445C40.1487 23.0209 40.1295 23.9353 39.5599 24.4872L26.646 37L20.4401 30.9869C19.8705 30.435 19.8513 29.5207 20.3972 28.9447C20.9431 28.3688 21.8474 28.3493 22.417 28.9013L26.646 32.9989L37.583 22.4016C38.1526 21.8496 39.0569 21.8691 39.6028 22.445Z" fill="#1EC28C"/>
  </svg>
);

export default TickInCircle;
import styles from "../Icon.module.css";

import React from "react";

const Download = ({ color = "#4D92DC" }) => (
  <svg className={`icon ${styles.download}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 14'>
    <path
      fill={color}
      fillRule='evenodd'
      d='M6 14h-.5A5.5 5.5 0 1 1 7.097 3.236a7 7 0 1 1 8.09 10.416c-.284.093-.673.173-1.166.24A.9.9 0 0 1 13 13v-.096a1 1 0 0 1 .857-.99c.188-.027.345-.058.47-.092A5 5 0 1 0 8.076 6.13 3.5 3.5 0 1 0 5.5 12h.499a1 1 0 0 1 0 2zm2.983-3.343V8.008A1 1 0 0 1 9.974 7a1 1 0 0 1 .991 1.008v2.7l.392-.399a.95.95 0 0 1 1.361 0 .99.99 0 0 1 0 1.384l-1.963 1.997c-.424.406-1.082.414-1.492.019l-1.981-2.014a.99.99 0 0 1 0-1.384.95.95 0 0 1 1.36 0l.34.346z'
    />
  </svg>
);

export default Download;

import styles from "../Icon.module.css";

import React from "react";

const ChatInCircle = ({color="#4D92DC"}) => (
  <svg className={`icon ${styles.chatInCircle}`} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="59" height="59" rx="29.5" fill="white"/>
    <path d="M30 17.4988C23.1077 17.4988 17.5 23.1065 17.5 29.9988C17.5 32.0459 18.0392 33.9593 18.9136 35.6653L17.5586 40.5164C17.243 41.6435 18.3575 42.7579 19.4849 42.4426L24.3396 41.0876C26.0442 41.9602 27.9548 42.4988 30 42.4988C36.8923 42.4988 42.5 36.8911 42.5 29.9988C42.5 23.1065 36.8923 17.4988 30 17.4988Z" fill={color}/>
  </svg>
);

export default ChatInCircle;
import styles from "../Icon.module.css";

import React from "react";

const Scales = ({color='#FFFFFF'}) => (
  <svg className={`icon ${styles.scales}`} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M9.99107 4.1063e-05C9.53912 0.00461249 9.13322 0.422907 9.14261 0.874221V2.56905L3.57854 3.45214C3.1789 3.51531 2.8544 3.89542 2.85512 4.29953V7.19856C1.94608 8.64476 1.03894 10.1127 0.131131 11.5515C-0.0258147 11.7985 -0.0433517 12.1278 0.086474 12.39C0.81144 13.8382 2.28353 14.5754 3.7125 14.5754C5.14148 14.5754 6.61356 13.8382 7.33853 12.39C7.46836 12.1278 7.45082 11.7985 7.29388 11.5515C6.38514 10.1069 5.47668 8.64888 4.56989 7.1986V5.02207L9.14262 4.29957V16.8905L5.12362 18.398C4.7497 18.5368 4.50718 18.9628 4.57891 19.3548C4.65064 19.7467 5.02836 20.0594 5.42728 20.0572H14.5727C14.9716 20.06 15.3494 19.7467 15.4211 19.3548C15.4928 18.9628 15.2503 18.5368 14.8764 18.398L10.8574 16.8905V4.02304L15.4301 3.30053V5.48593C14.5211 6.93213 13.6139 8.40011 12.7061 9.83889C12.5492 10.0859 12.5316 10.4152 12.6615 10.6774C13.3864 12.1255 14.8585 12.8628 16.2875 12.8628C17.7165 12.8628 19.1886 12.1255 19.9135 10.6774C20.0434 10.4152 20.0258 10.0859 19.8689 9.83889C18.9191 8.32648 17.9561 6.78322 17.1449 5.48597V2.3015C17.1487 1.81513 16.6635 1.38439 16.1804 1.44516C16.1714 1.44802 16.1625 1.45088 16.1536 1.45402L10.8574 2.29254V0.874221C10.867 0.416964 10.449 -0.00478751 9.99107 4.1063e-05ZM16.2875 7.33235L17.6093 9.43751H14.9657L16.2875 7.33235ZM3.7125 9.04505L5.03431 11.1502H2.3907L3.7125 9.04505Z' fill={color} />
  </svg>
);

export default Scales;

import styles from "../Icon.module.css";

const Shirt = ({ color = "#FFFFFF" }) => (
  <svg
    className={`icon ${styles.shirt}`}
    viewBox='0 0 30 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.16442 3.15488L2.41154 2.44814C3.50344 1.83043 5.08054 0.779632 6.10783 0.50518C7.0679 0.249579 8.00333 0.175544 8.00333 0.175544C8.00333 0.175544 8.50306 0.175544 9.12055 0.175544C9.7369 0.175544 10.1331 1.26006 10.7096 2.37342C11.8874 4.64738 14.8582 4.83179 14.8582 4.83179C17.3272 4.83179 18.498 3.58058 19.0517 2.37479C19.5759 1.23542 20.0512 0.17543 20.6696 0.17543H21.7899C22.5576 0.17543 23.1801 0.276036 23.6772 0.41786C24.6905 0.706959 26.2124 1.83168 27.3034 2.45189L28.8314 3.31884C29.9223 3.93894 30.3205 5.3347 29.7205 6.4366L28.4121 8.83978C27.8127 9.94121 26.6235 10.5085 25.7556 10.105C24.8881 9.70196 24.1846 10.392 24.1839 11.6473L24.1744 23.1307C24.1729 24.3866 23.3534 26.0298 22.1881 26.4934C19.0292 27.7515 14.8576 27.8245 14.8576 27.8245C11.9908 27.8245 9.4277 27.0459 7.74625 26.3707C6.58168 25.9043 5.69292 24.3853 5.69349 23.13L5.69791 11.6466C5.6986 10.391 5.03716 9.67335 4.22108 10.0405C3.40488 10.4087 2.25087 9.8171 1.64405 8.71862L0.284515 6.25968C-0.321276 5.1629 0.0718354 3.77191 1.16442 3.15488Z'
      fill={color}
    />
  </svg>
);

export default Shirt;

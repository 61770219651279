import React from 'react';

export default () => (
  <svg width='1.9rem' height='1.9rem' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 1.44001C0 0.644714 0.644714 0 1.44001 0H10.56C11.3553 0 12 0.644714 12 1.44001V10.56C12 11.3553 11.3553 12 10.56 12H1.44001C0.644714 12 0 11.3553 0 10.56V1.44001Z' fill='#ADB5BD' />
    <path d='M0 19.44C0 18.6447 0.644714 18 1.44001 18H10.56C11.3553 18 12 18.6447 12 19.44V28.56C12 29.3553 11.3553 30 10.56 30H1.44001C0.644714 30 0 29.3553 0 28.56V19.44Z' fill='#ADB5BD' />
    <path d='M18 1.44001C18 0.644714 18.6447 0 19.44 0H28.56C29.3553 0 30 0.644714 30 1.44001V10.56C30 11.3553 29.3553 12 28.56 12H19.44C18.6447 12 18 11.3553 18 10.56V1.44001Z' fill='#ADB5BD' />
    <path d='M18 19.44C18 18.6447 18.6447 18 19.44 18H28.56C29.3553 18 30 18.6447 30 19.44V28.56C30 29.3553 29.3553 30 28.56 30H19.44C18.6447 30 18 29.3553 18 28.56V19.44Z' fill='#ADB5BD' />
  </svg>
);

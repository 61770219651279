import styles from "../Icon.module.css";

const Search = ({ color = "#FFFFFF" }) => (
  <svg
    className={`icon ${styles.search}`}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.82827 11.4646C4.02353 11.2694 4.34011 11.2694 4.53537 11.4646C4.73063 11.6599 4.73063 11.9765 4.53537 12.1717L3.82827 11.4646ZM1.35355 15.3536C1.15829 15.5488 0.841709 15.5488 0.646447 15.3536C0.451184 15.1583 0.451184 14.8417 0.646447 14.6464L1.35355 15.3536ZM2.77273 7.36364C2.77273 10.602 5.39797 13.2273 8.63636 13.2273V14.2273C4.84568 14.2273 1.77273 11.1543 1.77273 7.36364H2.77273ZM8.63636 13.2273C11.8748 13.2273 14.5 10.602 14.5 7.36364H15.5C15.5 11.1543 12.427 14.2273 8.63636 14.2273V13.2273ZM14.5 7.36364C14.5 4.12524 11.8748 1.5 8.63636 1.5V0.5C12.427 0.5 15.5 3.57295 15.5 7.36364H14.5ZM8.63636 1.5C5.39797 1.5 2.77273 4.12524 2.77273 7.36364H1.77273C1.77273 3.57295 4.84568 0.5 8.63636 0.5V1.5ZM4.53537 12.1717L1.35355 15.3536L0.646447 14.6464L3.82827 11.4646L4.53537 12.1717Z'
      fill={color}
    />
  </svg>
);

export default Search;

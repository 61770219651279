import styles from "../Icon.module.css";

import React from "react";

const Moon = ({ color = "#FFFFFF" }) => (
  <svg
    className={`icon ${styles.moon}`}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M26.6074 2.23451C25.9867 2.24421 25.4911 2.75487 25.5 3.37562V4.50062H24.375C23.9693 4.49489 23.5919 4.70805 23.3874 5.05848C23.1828 5.40892 23.1828 5.84233 23.3874 6.19277C23.5919 6.5432 23.9693 6.75636 24.375 6.75063H25.5V7.87563C25.4943 8.28134 25.7074 8.65872 26.0579 8.86325C26.4083 9.06778 26.8417 9.06778 27.1921 8.86325C27.5426 8.65872 27.7557 8.28134 27.75 7.87563V6.75063H28.875C29.2807 6.75636 29.6581 6.5432 29.8626 6.19277C30.0672 5.84233 30.0672 5.40892 29.8626 5.05848C29.6581 4.70805 29.2807 4.49489 28.875 4.50062H27.75V3.37562C27.7544 3.07141 27.6353 2.7784 27.4201 2.5634C27.2048 2.34841 26.9116 2.22976 26.6074 2.23451L26.6074 2.23451ZM16.875 4.50062C8.811 4.50062 2.25 11.0616 2.25 19.1256C2.25 27.1896 8.811 33.7506 16.875 33.7506C22.125 33.7506 26.9985 30.8985 29.5942 26.3092C29.7787 25.983 29.7879 25.5854 29.6191 25.2501C29.4504 24.9149 29.1262 24.6858 28.7534 24.6393C22.1962 23.8293 17.25 18.2354 17.25 11.6256C17.25 9.72587 17.6609 7.87632 18.4717 6.12807C18.6292 5.78681 18.6059 5.38998 18.4102 5.06898C18.2144 4.74873 17.8711 4.54714 17.4961 4.53139L17.3071 4.51967C17.1639 4.50992 17.0205 4.50062 16.875 4.50062H16.875ZM30.3574 11.9845C29.7367 11.9942 29.2411 12.5049 29.25 13.1256V14.2506H28.125C27.7193 14.2449 27.3419 14.458 27.1374 14.8085C26.9328 15.1589 26.9328 15.5923 27.1374 15.9428C27.3419 16.2932 27.7193 16.5064 28.125 16.5006H29.25V17.6256C29.2443 18.0313 29.4574 18.4087 29.8079 18.6132C30.1583 18.8178 30.5917 18.8178 30.9421 18.6132C31.2926 18.4087 31.5057 18.0313 31.5 17.6256V16.5006H32.625C33.0307 16.5064 33.4081 16.2932 33.6126 15.9428C33.8172 15.5923 33.8172 15.1589 33.6126 14.8085C33.4081 14.458 33.0307 14.2449 32.625 14.2506H31.5V13.1256C31.5044 12.8214 31.3853 12.5284 31.1701 12.3134C30.9548 12.0984 30.6616 11.9798 30.3574 11.9845Z'
      fill={color}
    />
  </svg>
);

export default Moon;

import styles from "../Icon.module.css";

const HatInCircle = ({ color = "#4D92DC" }) => (
  <svg
    className={`icon ${styles.hatInCircle}`}
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='59' height='59' rx='29.5' fill='white' />
    <path
      d='M42 27.7937V34.7921C42 35.0245 41.9077 35.2474 41.7433 35.4118C41.579 35.5761 41.3561 35.6684 41.1237 35.6684C40.8913 35.6684 40.6684 35.5761 40.5041 35.4118C40.3397 35.2474 40.2474 35.0245 40.2474 34.7921V27.9258L29.9959 33.7838C29.9269 33.825 29.8481 33.8468 29.7678 33.8468C29.6874 33.8468 29.6086 33.825 29.5397 33.7838L18.2198 27.3135C18.1527 27.2729 18.0972 27.2156 18.0587 27.1473C18.0202 27.079 18 27.0018 18 26.9234C18 26.845 18.0202 26.7679 18.0587 26.6995C18.0972 26.6312 18.1527 26.5739 18.2198 26.5333L29.5397 20.063C29.6086 20.0218 29.6874 20 29.7678 20C29.8481 20 29.9269 20.0218 29.9959 20.063L41.2437 26.4973C41.4733 26.627 41.6644 26.8154 41.7972 27.0432C41.9301 27.271 42.0001 27.53 42 27.7937ZM37.4624 31.527L30.1159 35.7285C30.0114 35.7933 29.8908 35.8277 29.7678 35.8277C29.6448 35.8277 29.5242 35.7933 29.4197 35.7285L22.0611 31.527C21.9549 31.4685 21.8353 31.4387 21.714 31.4406C21.5928 31.4425 21.4741 31.476 21.3699 31.5379C21.2656 31.5997 21.1792 31.6877 21.1194 31.7932C21.0596 31.8987 21.0283 32.018 21.0288 32.1392V35.2723C21.0301 35.3908 21.0616 35.5071 21.1204 35.61C21.1793 35.713 21.2634 35.7992 21.3649 35.8605L29.4076 40.6622C29.5167 40.7266 29.6411 40.7605 29.7678 40.7605C29.8945 40.7605 30.0188 40.7266 30.1279 40.6622L38.1707 35.8605C38.2721 35.7992 38.3563 35.713 38.4151 35.61C38.4739 35.5071 38.5055 35.3908 38.5068 35.2723V32.1392C38.5084 32.0162 38.4775 31.895 38.417 31.7878C38.3566 31.6807 38.2688 31.5915 38.1627 31.5293C38.0566 31.4671 37.9359 31.4341 37.8129 31.4337C37.6899 31.4333 37.569 31.4655 37.4624 31.527Z'
      fill={color}
    />
  </svg>
);

export default HatInCircle;

import styles from "../Icon.module.css";

const Share = () => (
  <svg className={`icon ${styles.share}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 31'>
    <path
      fill='#ffffff'
      fillRule='nonzero'
      d='M15.5 31C6.94 25 4 24.06 4 15.5 4 6.94 6.94 4 15.5 4 24.06 0 25 6.94 25 15.5 25 24.06 24.06 25 15.5 31zm3.063-18'
    />
    <path
      fill='#4D92DC'
      fillRule='nonzero'
      d='M15.5 31C6.94 31 0 24.06 0 15.5 0 6.94 6.94 0 15.5 0 24.06 0 31 6.94 31 15.5 31 24.06 24.06 31 15.5 31zm3.063-18.563a.604.604 0 0 0-.875 0l-5.25 5.25a.604.604 0 0 0 0 .875c.25.25.624.25.874 0l5.25-5.25a.604.604 0 0 0 0-.874zm-5.25 9.626l2.187-2.188a3.106 3.106 0 0 0 .688-3.313l-1.063 1.063c0 .5-.125 1-.563 1.438l-2.187 2.187c-.75.75-1.938.75-2.625 0-.75-.75-.75-1.938 0-2.625l2.188-2.188c.374-.375.937-.562 1.437-.562l1.063-1.063c-1.126-.437-2.438-.25-3.313.688l-2.188 2.188c-1.187 1.187-1.187 3.187 0 4.375a3.02 3.02 0 0 0 4.376 0zM16.375 12l2.188-2.188c.75-.75 1.937-.75 2.625 0 .687.75.75 1.938 0 2.626L19 14.624c-.375.375-.938.563-1.438.563L16.5 16.25a3.106 3.106 0 0 0 3.313-.688L22 13.376c1.188-1.188 1.188-3.188 0-4.375-1.188-1.188-3.188-1.188-4.375 0L15.5 11.125a3.106 3.106 0 0 0-.688 3.313l1.063-1.063c-.063-.5.125-1 .5-1.375z'
    />
  </svg>
);

export default Share;

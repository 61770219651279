import { createContext, useEffect, useState } from "react";
import * as AuthenticationService from "../../services/authService";
import setRefreshToken from "../../../helpers/setRefreshToken";
import returnCookieValue from "../../../helpers/returnCookieValue";

const AuthorizationContext = createContext(null);
const AuthorizationContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    loading: true,
    data: null,
  });
  const [authorization, setAuthorization] = useState({
    access_token: null,
    refresh_token: (() => {
      if (typeof window !== "undefined") {
        return returnCookieValue("refresh_token");
      }

      return null;
    })(),
  });

  useEffect(() => {
    if (user.loading) {
      const { refresh_token } = authorization;

      if (refresh_token) {
        AuthenticationService.refreshToken(refresh_token).then(
          ({ access_token, refresh_token, message }) => {
            if (message && message.includes("Wrong")) {
              setRefreshToken({ token: refresh_token, action: "delete" }); // Refresh Failed
            } else Value.login({ access_token, refresh_token });
          }
        );
      } else {
        setUser({ ...user, loading: false });
      }
    }
  }, []);

  const Value = {
    user: user.data,
    access_token: authorization.access_token,
    login: ({ access_token, refresh_token }) => {
      setAuthorization({ ...authorization, access_token, refresh_token });
      setRefreshToken({ token: refresh_token, action: "set" });
      AuthenticationService.getUser(access_token)
        .then((userData) => {
          return setUser({ loading: false, data: userData });
        });
    },
    refresh: () => {},
  };

  return (
    <AuthorizationContext.Provider value={Value}>
      {(() => {
        return children;
      })()}
    </AuthorizationContext.Provider>
  );
};

export { AuthorizationContext, AuthorizationContextProvider };

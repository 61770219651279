import styles from "../Icon.module.css";

import React from "react";

const Phone = ({ color = "#4D92DC" }) => (
  <svg
    className={`icon ${styles.phone}`}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.4211 13.1684C17.1873 13.1684 15.979 12.9758 14.8337 12.5979C14.2747 12.4053 13.64 12.5537 13.2726 12.9284L11.0021 14.6432C8.39685 13.2527 6.72948 11.5863 5.35792 9.00001L7.02632 6.78316C7.44632 6.36316 7.59686 5.74841 7.41686 5.17264C7.0358 4.02 6.84213 2.81053 6.84213 1.57895C6.84213 0.708405 6.13372 0 5.26318 0H1.57895C0.708405 0 0 0.708405 0 1.57895C0 11.7358 8.26422 20 18.4211 20C19.2916 20 20 19.2916 20 18.4211V14.7474C20 13.8768 19.2916 13.1684 18.4211 13.1684Z'
      fill={color}
    />
  </svg>
);

export default Phone;

import styles from "../Icon.module.css";

const Vk = ({ color = "#FFFFFF" }) => (
  <svg
    className={`icon ${styles.vk}`}
    viewBox='0 0 25 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M23.2056 0.554479H19.2862C18.9602 0.554477 18.6607 0.71951 18.5065 0.984003C18.5065 0.984003 16.9433 3.63527 16.4412 4.52828C15.0903 6.93055 14.2156 6.17639 14.2156 5.06147V1.21723C14.2156 0.548285 13.6267 0.00599946 12.9003 0.00599946H9.95341C9.13743 -0.0498722 8.34822 0.287376 7.8677 0.897278C7.8677 0.897278 9.3641 0.674464 9.3641 2.53128C9.3641 2.99172 9.38985 4.31629 9.41332 5.42725C9.42238 5.76258 9.20337 6.06752 8.86473 6.19107C8.5261 6.31461 8.13974 6.23053 7.89698 5.98046C6.69076 4.43549 5.69161 2.76199 4.92123 0.996333C4.78613 0.726618 4.49285 0.554046 4.17032 0.554479C3.32031 0.554479 1.61936 0.554479 0.609228 0.554479C0.41039 0.553232 0.223578 0.642029 0.109498 0.792011C-0.0045826 0.941994 -0.031369 1.13402 0.0378429 1.30569C1.11916 4.03718 5.77714 12.621 11.0938 12.621H13.3315C13.8198 12.621 14.2156 12.2565 14.2156 11.8069V10.5625C14.2156 10.2434 14.4214 9.9549 14.7394 9.82824C15.0573 9.70157 15.4266 9.76099 15.6791 9.97945L18.3576 12.297C18.5981 12.5051 18.9158 12.621 19.2458 12.621H22.7631C24.4595 12.621 24.4595 11.537 23.5346 10.6981C22.8837 10.1078 20.5348 7.82778 20.5348 7.82778C20.1192 7.43128 20.0796 6.81494 20.4415 6.37604C21.2007 5.45595 22.4425 3.94984 22.9691 3.30463C23.689 2.42274 24.9918 0.554479 23.2056 0.554479Z'
      fill={color}
    />
  </svg>
);

export default Vk;

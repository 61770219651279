import styles from "../Icon.module.css";

import React from "react";

const Bag = ({color='#FFFFFF'}) => (
  <svg className={`icon ${styles.bag}`} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M28.4515 18.3543C28.4515 17.802 28.0037 17.3543 27.4515 17.3543H18.3618V18.4305C18.3618 19.0251 17.8801 19.5068 17.2856 19.5068H12.7117C12.1172 19.5068 11.6355 19.0247 11.6355 18.4305V17.3543H2.5459C1.99361 17.3543 1.5459 17.802 1.5459 18.3543V27.0001C1.5459 28.657 2.88904 30.0001 4.5459 30.0001H25.4516C27.1085 30.0001 28.4516 28.6569 28.4516 27.0001L28.4515 18.3543Z' fill={color} />
    <path d='M0.335938 6.86096V14.2019C0.335938 14.7541 0.783653 15.2019 1.33594 15.2019H11.6362V14.1256C11.6362 13.5311 12.118 13.0494 12.7124 13.0494H17.2865C17.881 13.0494 18.3627 13.5311 18.3627 14.1256V15.2019H28.663C29.2153 15.2019 29.663 14.7541 29.663 14.2019V6.86096C29.663 6.26646 29.1813 5.78475 28.5868 5.78475H20.9986C21.2758 5.56301 21.4569 5.22562 21.4569 4.84303C21.4569 2.12717 18.6206 0 14.9996 0C11.3787 0 8.54232 2.12717 8.54232 4.84298C8.54232 5.22562 8.7235 5.56296 9.0007 5.7847H1.41216C0.817897 5.78475 0.335938 6.26671 0.335938 6.86096ZM10.9637 4.84298C10.9637 3.55302 12.8498 2.42134 14.9996 2.42134C17.1495 2.42134 19.0355 3.55302 19.0355 4.84298C19.0355 5.22562 19.2167 5.56296 19.494 5.7847H10.5053C10.7827 5.56301 10.9637 5.22586 10.9637 4.84298Z' fill={color} />
  </svg>
);

export default Bag;

import React from "react";
import styles from "./Icon.module.css";
import Pack from "./Pack";

interface Props {
  name: string;
  color?: string;
}

export default function Icon({ name, color }: Props): React.ReactElement {
  //@ts-ignore
  const Component = Pack[name];
  if (Component) return <Component color={color} />;
  return <i className={`${styles.component} ${styles[name]}`} />;
}

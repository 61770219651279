import styles from "./AdvantagesList.module.css";
import Hint from "../../../ui/Hint";
import Icon from "../../../ui/Icon";

const AdvantagesList = ({ array, template = "default" }) => {
  return (
    <ul className={`${styles.advantagesList} ${styles[`advantagesList--${template}`]}`}>
      {array.map((item) => (
        <li key={item.id} className={styles.advantagesList__item}>
          {item.icon ? (
            <div className={`${styles.advantagesList__item__section_icon}`}>
              <Icon name={item.icon} />
            </div>
          ) : null}
          {item.title}
          {item.hint ? (
            <div
              className={`${styles.advantagesList__item__section} ${styles.advantagesList__item__section_hint}`}
            >
              <Hint>{item.hint}</Hint>
            </div>
          ) : null}
        </li>
      ))}
    </ul>
  );
};

export default AdvantagesList;

import styles from "../Icon.module.css";

const DocumentWithMessage = ({ color = "#4D92DC" }) => (
  <svg
    className={`icon ${styles.documentWithMessage}`}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.70513 1.63399e-06C2.49711 1.63399e-06 1.5 1.00952 1.5 2.23256V20.093C1.5 21.3161 2.49711 22.3256 3.70513 22.3256H10.8718V20.6512H3.70513C3.3905 20.6512 3.15385 20.4116 3.15385 20.093V2.23256C3.15385 1.91402 3.3905 1.67442 3.70513 1.67442H10.3205V6.69767C10.3205 7.92071 11.3176 8.93023 12.5256 8.93023H17.4872V13.9535H19.141V8.78634C19.141 8.12076 18.8794 7.48144 18.4142 7.01054L12.2166 0.735828C11.7517 0.264306 11.1203 0 10.4626 0L3.70513 1.63399e-06ZM11.9744 2.85938L16.3168 7.25582H12.5256C12.211 7.25582 11.9744 7.01622 11.9744 6.69768L11.9744 2.85938ZM13.6282 15.0698C12.777 15.0698 12.0842 15.7233 11.9927 16.561L17.4872 19.4629L22.9817 16.561C22.8902 15.7233 22.1973 15.0698 21.3462 15.0698H13.6282ZM11.9744 17.8103V22.3256C11.9744 23.2504 12.7147 24 13.6282 24H21.3462C22.2596 24 23 23.2504 23 22.3256V17.8103L17.7424 20.5879C17.6619 20.6298 17.5748 20.6512 17.4872 20.6512C17.3995 20.6512 17.3125 20.6298 17.232 20.5879L11.9744 17.8103Z'
      fill={color}
    />
  </svg>
);

export default DocumentWithMessage;

import styles from "../Icon.module.css";

const Zoon = ({ color = "#6339F5" }) => (
  <svg
    className={`icon ${styles.zoon}`}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.55526 0H38.4372C39.2961 0 39.9924 0.696314 39.9924 1.55526V8.80806C39.9924 9.77415 39.5732 10.6889 38.8434 11.3219L18.2188 28.5758H38.4372C39.2961 28.5758 39.9924 29.2594 39.9924 30.1183V38.4447C39.9924 39.3037 39.2961 40 38.4372 40H1.55526C0.696314 40 0 39.3037 0 38.4447V31.2038C0 30.2377 0.41922 29.323 1.14904 28.69L21.7737 11.4361H1.55526C0.696314 11.4361 0 10.724 0 9.86501V1.55526C0 0.696314 0.696314 0 1.55526 0Z'
      fill={color}
    />
  </svg>
);

export default Zoon;

import styles from "../Icon.module.css";

const Car = ({ color = "#FFFFFF" }) => (
  <svg
    className={`icon ${styles.car}`}
    viewBox='0 0 30 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M27.7388 10.5616L25.6348 3.54834C25.155 1.94891 23.7112 0.875 22.0422 0.875H7.95776C6.28876 0.875 4.84496 1.94891 4.36522 3.54834L2.26123 10.5616C0.932687 11.139 0 12.4611 0 14V21.5C0 22.5355 0.839539 23.375 1.875 23.375H1.87511L1.875 25.2499C1.875 26.2855 2.71442 27.125 3.75 27.125H5.62511C6.66057 27.125 7.50011 26.2855 7.50011 25.25L7.49931 23.375H22.5L22.4999 25.2499C22.4999 26.2855 23.3393 27.125 24.3749 27.125H26.25C27.2856 27.125 28.125 26.2855 28.125 25.25V23.3751L28.1243 23.375H28.125C29.1605 23.375 30 22.5355 30 21.5V14C30 12.4611 29.0673 11.139 27.7388 10.5616L27.7388 10.5616ZM7.95771 4.625H22.0422L23.7297 10.25H6.27016L7.9577 4.625H7.95771ZM7.49994 18.6875C6.46437 18.6875 5.62494 17.848 5.62494 16.8125C5.62494 15.7769 6.46437 14.9375 7.49994 14.9375C8.53552 14.9375 9.37494 15.7769 9.37494 16.8125C9.37494 17.848 8.53552 18.6875 7.49994 18.6875ZM22.4999 18.6875C21.4644 18.6875 20.6249 17.848 20.6249 16.8125C20.6249 15.7769 21.4644 14.9375 22.4999 14.9375C23.5355 14.9375 24.3749 15.7769 24.3749 16.8125C24.3749 17.848 23.5355 18.6875 22.4999 18.6875Z'
      fill={color}
    />
  </svg>
);

export default Car;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const widgetDivs = document.querySelectorAll('.destra-widget-form');

widgetDivs.forEach(div => {
  ReactDOM.render(
    <React.StrictMode>
      <App injectTo={div} />
    </React.StrictMode>,
    div
  );
})


import styles from "./Checkbox.module.css";

const Checkbox = ({ checked = false, r, handleCheck, handleChange, error = false }) => {
  const params = {
    className: styles.hidden,
    type: "checkbox",
    ref: (e) => {
      if (r instanceof Function) r(e);
    },
    checked,
    onChange: () => handleChange()
  };

  return (
    <div
      onClick={handleCheck}
      className={`${styles.component} ${styles[checked ? "checked" : "unchecked"]} ${
        error && styles.checkbox__error
      }`}
    >
      <input {...params} />
    </div>
  );
};
export default Checkbox;

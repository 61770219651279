import styles from "../Icon.module.css";

const Ruble = ({ color = "#FFFFFF" }) => (
  <svg
    className={`icon ${styles.ruble}`}
    viewBox='0 0 50 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.625 3.125C16.5204 3.125 15.625 4.02043 15.625 5.125V23C15.625 24.1046 14.7296 25 13.625 25H10.9375C10.0746 25 9.375 25.6996 9.375 26.5625C9.375 27.4254 10.0746 28.125 10.9375 28.125H14.0625C14.9254 28.125 15.625 28.8246 15.625 29.6875C15.625 30.5504 14.9254 31.25 14.0625 31.25H10.9375C10.0746 31.25 9.375 31.9496 9.375 32.8125C9.375 33.6754 10.0746 34.375 10.9375 34.375H13.625C14.7296 34.375 15.625 35.2704 15.625 36.375V42.1875C15.625 43.0504 16.3246 43.75 17.1875 43.75C18.0504 43.75 18.75 43.0504 18.75 42.1875V36.375C18.75 35.2704 19.6454 34.375 20.75 34.375H26.5625C27.4254 34.375 28.125 33.6754 28.125 32.8125C28.125 31.9496 27.4254 31.25 26.5625 31.25H20.3125C19.4496 31.25 18.75 30.5504 18.75 29.6875C18.75 28.8246 19.4496 28.125 20.3125 28.125H28.125C35.0098 28.125 40.625 22.5098 40.625 15.625C40.625 8.74024 35.0098 3.125 28.125 3.125H17.625ZM18.75 8.25C18.75 7.14543 19.6454 6.25 20.75 6.25H28.125C33.3252 6.25 37.5 10.4248 37.5 15.625C37.5 20.8252 33.3252 25 28.125 25H20.75C19.6454 25 18.75 24.1046 18.75 23V8.25Z'
      fill={color}
    />
  </svg>
);

export default Ruble;

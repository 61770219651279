import styles from "../Icon.module.css";

const DocumentWithPen = ({ color = "#4D92DC" }) => (
  <svg
    className={`icon ${styles.documentWithPen}`}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.6054 0C2.16895 0 1 1.15369 1 2.57137V20.2853C1 21.7029 2.16895 22.8566 3.6054 22.8566H9.13056C9.1514 22.7143 9.18587 22.5736 9.23798 22.437L9.23911 22.4381C9.2698 22.3101 9.30885 22.1763 9.3488 22.0374L9.60889 21.1424H3.6054C3.12658 21.1424 2.73693 20.7578 2.73693 20.2853V2.57137C2.73693 2.09881 3.12658 1.71425 3.6054 1.71425H10.2636V6.57128C10.2636 7.98896 11.4326 9.14265 12.869 9.14265H17.7903V11.3323C18.3004 10.8615 18.8944 10.5423 19.5273 10.3949V8.28553C19.5273 8.04867 19.4299 7.83458 19.2728 7.67951L11.7461 0.251111C11.589 0.0960425 11.3721 0 11.1321 0H3.6054ZM12.0006 2.92627L16.5623 7.42841H12.869C12.3902 7.42841 12.0006 7.04384 12.0006 6.57128V2.92627ZM20.3946 11.4283C20.236 11.4289 20.0755 11.4427 19.914 11.4718C19.4022 11.5627 18.9133 11.8261 18.5005 12.2341L11.6692 18.9761C11.4625 19.1801 11.3104 19.4345 11.2293 19.7105L10.3202 22.8421C10.2785 22.9507 10.2596 23.0654 10.2636 23.1803C10.2712 23.3877 10.3537 23.5928 10.5147 23.7517C10.675 23.91 10.8818 23.9915 11.0914 23.9995C11.2078 24.0035 11.3257 23.9854 11.4363 23.9437L14.6093 23.0464C14.889 22.9664 15.145 22.8168 15.3523 22.6122L22.1847 15.8702C22.5981 15.4628 22.865 14.9803 22.957 14.4751C22.986 14.3157 22.9994 14.1574 23 14.0008C23.0017 13.3185 22.7352 12.6736 22.2367 12.1828C21.7393 11.6913 21.0859 11.4272 20.3946 11.4283Z'
      fill={color}
    />
  </svg>
);

export default DocumentWithPen;

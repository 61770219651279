import styles from "../Icon.module.css";

const Info = ({ color = "#1E1E1E" }) => (
  <svg
    className={`icon ${styles.info}`}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='15' cy='15' r='14.5' fill='white' stroke='#E2E5E7' />
    <path
      opacity='0.4'
      d='M14.374 19.9279H15.634V12.1279H14.374V19.9279ZM14.209 9.59291C14.209 10.0429 14.554 10.3729 15.019 10.3729C15.439 10.3729 15.799 10.0429 15.799 9.59291C15.799 9.17291 15.439 8.85791 15.019 8.85791C14.569 8.85791 14.209 9.17291 14.209 9.59291Z'
      fill={color}
    />
  </svg>
);

export default Info;

import styles from "../Icon.module.css";

import React from "react";

const File = ({ color = "#4D92DC" }) => (
  <svg
    className={`icon ${styles.file}`}
    viewBox='0 0 14 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.8327 4.83361C12.8319 4.7495 12.8029 4.66709 12.748 4.60524L10.5171 2.096C10.4644 2.0368 10.3913 2 10.311 2H1.78018C1.44185 2 1.16663 2.30953 1.16663 2.69008V15.3099C1.16663 15.6904 1.44182 16 1.78016 16H12.2198C12.5581 16 12.8333 15.6905 12.8333 15.3099V4.837L12.833 4.83508L12.8327 4.83361ZM10.6024 3.11922L11.8382 4.50922H10.6331C10.6162 4.50922 10.6024 4.49371 10.6024 4.47471V3.11922ZM12.2198 15.3444C12.2367 15.3444 12.2504 15.329 12.2504 15.31L12.2505 5.16483H10.6331C10.2948 5.16483 10.0196 4.85522 10.0196 4.4747V2.6556H1.78019C1.76329 2.6556 1.74953 2.67108 1.74953 2.69008V15.31C1.74953 15.329 1.76329 15.3444 1.78019 15.3444H12.2198Z'
      fill={color}
    />
    <path
      d='M10.7872 6.66752H3.21273C3.04921 6.66752 2.91661 6.81675 2.91661 7.00085C2.91661 7.18493 3.04921 7.33419 3.21273 7.33419H10.7872C10.9507 7.33419 11.0833 7.18493 11.0833 7.00085C11.0833 6.81675 10.9507 6.66752 10.7872 6.66752Z'
      fill={color}
    />
    <path
      d='M10.7872 8.66646H3.21273C3.04921 8.66646 2.91661 8.81572 2.91661 8.9998C2.91661 9.18387 3.04921 9.33313 3.21273 9.33313H10.7872C10.9507 9.33313 11.0833 9.18387 11.0833 8.9998C11.0833 8.81572 10.9507 8.66646 10.7872 8.66646Z'
      fill={color}
    />
    <path
      d='M10.7872 10.6654H3.21273C3.04921 10.6654 2.91661 10.8147 2.91661 10.9987C2.91661 11.1828 3.04921 11.3321 3.21273 11.3321H10.7872C10.9507 11.3321 11.0833 11.1828 11.0833 10.9987C11.0833 10.8147 10.9507 10.6654 10.7872 10.6654Z'
      fill={color}
    />
    <path
      d='M6.09401 12.6666H3.2392C3.06106 12.6666 2.91661 12.8159 2.91661 13C2.91661 13.1841 3.06106 13.3333 3.2392 13.3333H6.09401C6.27216 13.3333 6.41661 13.1841 6.41661 13C6.41661 12.8159 6.27216 12.6666 6.09401 12.6666Z'
      fill={color}
    />
  </svg>
);

export default File;

import styles from "../Icon.module.css";

const SquaresInCircle = ({ color = "#4D92DC" }) => (
  <svg
    className={`icon ${styles.squaresInCircle}`}
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='59' height='59' rx='29.5' fill='white' />
    <rect x='18' y='18' width='9.9999' height='9.9999' rx='1' fill={color} />
    <rect x='18' y='32' width='9.9999' height='9.9999' rx='1' fill={color} />
    <rect x='31.9995' y='18' width='9.9999' height='9.9999' rx='1' fill={color} />
    <rect x='31.9995' y='32' width='9.9999' height='9.9999' rx='1' fill={color} />
  </svg>
);

export default SquaresInCircle;

import React from 'react';

const DocTick = ({ color = '#1EC28C' }) => (
  <svg className='icon icon--doc-tick' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M17.8125 0H3.4375C1.5375 0 0 1.5375 0 3.4375V22.8125C0 24.7125 1.5375 26.25 3.4375 26.25H11.675C11 24.825 10.625 23.2375 10.625 21.5625C10.625 20.125 10.9 18.75 11.425 17.4875C11.675 16.85 11.9875 16.2375 12.35 15.675C13.1625 14.4125 14.2125 13.3375 15.45 12.5C16.0875 12.0625 16.7625 11.7 17.4875 11.425C18.65 10.9375 19.925 10.6625 21.25 10.6375V3.4375C21.25 1.5375 19.7125 0 17.8125 0Z' fill='#ECEFF1' />
    <path d='M17.5 11.25C17.5 11.3125 17.5 11.375 17.4875 11.425C16.7625 11.7 16.0875 12.0625 15.45 12.5H5C4.3125 12.5 3.75 11.9375 3.75 11.25C3.75 10.5625 4.3125 10 5 10H16.25C16.9375 10 17.5 10.5625 17.5 11.25Z' fill='#90A4AE' />
    <path d='M12.35 15.675C11.9875 16.2375 11.675 16.85 11.425 17.4875C11.375 17.5 11.3125 17.5 11.25 17.5H5C4.3125 17.5 3.75 16.9375 3.75 16.25C3.75 15.5625 4.3125 15 5 15H11.25C11.725 15 12.15 15.275 12.35 15.675Z' fill='#90A4AE' />
    <path d='M10 7.5H5C4.31 7.5 3.75 6.94 3.75 6.25C3.75 5.56 4.31 5 5 5H10C10.69 5 11.25 5.56 11.25 6.25C11.25 6.94 10.69 7.5 10 7.5Z' fill='#90A4AE' />
    <path d='M21.5625 30C16.91 30 13.125 26.215 13.125 21.5625C13.125 16.91 16.91 13.125 21.5625 13.125C26.215 13.125 30 16.91 30 21.5625C30 26.215 26.215 30 21.5625 30Z' fill={color} />
    <path d='M18 21.7273L20.1875 24L25 19' stroke='white' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export default DocTick;

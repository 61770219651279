import styles from "../Icon.module.css";

import React from "react";

const Send = ({ color = "#4D92DC" }) => (
  <svg
    className={`icon ${styles.send}`}
    viewBox='0 0 18 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.21289 6.79092C6.75176 4.35422 13.9195 1.38338 14.9089 0.972195C17.5043 -0.104024 18.3006 0.102229 17.9035 2.4856C17.6184 4.19865 16.7956 9.87017 16.1398 13.4003C15.7507 15.4937 14.8776 15.7418 13.5048 14.8361C12.8446 14.4003 9.51238 12.1967 8.78917 11.6793C8.12898 11.2077 7.21847 10.6405 8.36036 9.52333C8.76669 9.12537 11.4304 6.58202 13.5057 4.60234C13.7776 4.34232 13.436 3.91527 13.1223 4.12373C10.3251 5.97868 6.44678 8.55332 5.95319 8.88871C5.2075 9.39508 4.49134 9.62734 3.20579 9.25802C2.23446 8.97905 1.28561 8.64631 0.916287 8.51939C-0.505891 8.03108 -0.168305 7.39866 1.21289 6.79092V6.79092Z'
      fill={color}
    />
  </svg>
);

export default Send;

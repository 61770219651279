import styles from "../Icon.module.css";

import React from "react";

const Google = () => (
  <svg className={`icon ${styles.google}`} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M29.3635 15.3375C29.3635 14.35 29.276 13.4125 29.126 12.5H15.001V18.1375H23.0885C22.726 19.9875 21.6635 21.55 20.0885 22.6125V26.3625H24.9135C27.7385 23.75 29.3635 19.9 29.3635 15.3375Z' fill='#4285F4' />
    <path d='M15.0008 30C19.0508 30 22.4383 28.65 24.9133 26.3625L20.0883 22.6125C18.7383 23.5125 17.0258 24.0625 15.0008 24.0625C11.0883 24.0625 7.77578 21.425 6.58828 17.8625H1.61328V21.725C4.07578 26.625 9.13828 30 15.0008 30Z' fill='#34A853' />
    <path d='M6.58848 17.8624C6.27598 16.9624 6.11348 15.9999 6.11348 14.9999C6.11348 13.9999 6.28848 13.0374 6.58848 12.1374V8.2749H1.61348C0.588475 10.2999 0.000976562 12.5749 0.000976562 14.9999C0.000976562 17.4249 0.588475 19.6999 1.61348 21.7249L6.58848 17.8624Z' fill='#FBBC05' />
    <path d='M15.0008 5.9375C17.2133 5.9375 19.1883 6.7 20.7508 8.1875L25.0258 3.9125C22.4383 1.4875 19.0508 0 15.0008 0C9.13828 0 4.07578 3.375 1.61328 8.275L6.58828 12.1375C7.77578 8.575 11.0883 5.9375 15.0008 5.9375Z' fill='#EA4335' />
  </svg>
);

export default Google;

import styles from "../Icon.module.css";

const Login = ({ color = "#FFFFFF" }) => (
  <svg className={`icon ${styles.login}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14'>
    <path
      className='icon__zone'
      fill={color}
      fillRule='evenodd'
      d='M4 1a1 1 0 0 0 1 1h8a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H5a1 1 0 0 0 0 2h9a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5a1 1 0 0 0-1 1zm5.705 6.712l-2.059 2.06a.97.97 0 0 1-1.372-1.373L6.672 8H1a1 1 0 0 1 0-2h5.676l-.399-.399A.97.97 0 0 1 7.65 4.23l2.059 2.059a.966.966 0 0 1 .24.397.999.999 0 0 1-.09.828.972.972 0 0 1-.154.2z'
    />
  </svg>
);

export default Login;

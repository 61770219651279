import styles from "../Icon.module.css";

import React from "react";

const Gear = ({color='#FFFFFF'}) => (
  <svg className={`icon ${styles.gear}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 15'>
    <path fill={color} fillRule='nonzero' d='M13.37 9l-.749-.438a1.287 1.287 0 0 1 0-2.187l.75-.438c.624-.375.811-1.125.437-1.687l-.625-1.063c-.375-.624-1.124-.812-1.686-.437l-.75.438c-.812.5-1.873-.126-1.873-1.063V1.25C8.874.562 8.312 0 7.624 0H6.376c-.687 0-1.249.563-1.249 1.25v.813c0 .937-1.061 1.562-1.873 1.062l-.75-.375a1.21 1.21 0 0 0-1.686.438L.192 4.25C-.12 4.875.067 5.625.63 6l.75.438c.812.437.812 1.687 0 2.125L.629 9c-.624.375-.811 1.125-.437 1.688l.625 1.062c.375.625 1.124.813 1.686.438l.75-.376c.812-.5 1.873.126 1.873 1.063v.875c0 .688.562 1.25 1.25 1.25h1.249c.687 0 1.249-.563 1.249-1.25v-.813c0-.937 1.061-1.562 1.873-1.062l.75.438a1.21 1.21 0 0 0 1.686-.438l.625-1.063c.312-.687.125-1.437-.437-1.812zM7 10a2.506 2.506 0 0 1-2.498-2.5C4.502 6.125 5.626 5 7 5a2.506 2.506 0 0 1 2.498 2.5C9.498 8.875 8.374 10 7 10z' />
  </svg>
);

export default Gear;

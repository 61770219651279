import styles from "../Icon.module.css";

import React from "react";

const Cursor = ({color='#FFFFFF'}) => (
  <svg className={`icon ${styles.cursor}`} viewBox="0 0 35 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5227 8.57031C11.679 8.57031 11.8352 8.53125 11.9915 8.49219C12.5774 8.25781 12.8899 7.55469 12.6165 6.96875L10.3509 1.53906C10.1165 0.953126 9.41336 0.640626 8.82743 0.914062C8.24149 1.14844 7.92899 1.85156 8.20243 2.4375L10.4681 7.86719C10.6243 8.29688 11.0931 8.57031 11.5227 8.57031H11.5227ZM7.42118 11.1875L1.83524 9.42969C1.21024 9.23438 0.546176 9.58594 0.350862 10.2109C0.155551 10.8359 0.507112 11.5 1.13211 11.6953L6.71805 13.4141C6.83524 13.4531 6.95243 13.4531 7.06961 13.4531C7.57743 13.4531 8.04618 13.1406 8.20243 12.6328C8.39774 12.0078 8.04618 11.3828 7.42118 11.1875ZM14.6087 41.7734C14.8431 41.8516 15.0774 41.9297 15.3118 41.9297C15.8587 41.9297 16.3665 41.6953 16.7181 41.3047L21.9915 35.6797L25.5852 44.3516C25.7806 44.8203 26.2102 45.0547 26.679 45.0547C26.8352 45.0547 26.9915 45.0156 27.1477 44.9766C27.7337 44.7422 28.0462 44.0391 27.7727 43.4531L24.1399 34.7422H32.1087C32.8899 34.7422 33.6321 34.2734 33.9055 33.5313C34.218 32.7891 34.0227 31.9688 33.4368 31.3828L16.679 15.0547C16.1321 14.5078 15.2727 14.3516 14.5696 14.6641C13.8274 14.9766 13.3977 15.6797 13.3977 16.4609V39.9766C13.3587 40.7969 13.8274 41.5 14.6087 41.7734L14.6087 41.7734ZM15.7024 17.3984L31.1712 32.3984H22.3821C22.0696 32.3984 21.7571 32.5156 21.5227 32.7891L15.7024 39.0391V17.3984ZM19.8431 1.96875L17.1087 7.16406C16.7962 7.75 17.0306 8.45312 17.6165 8.72656C17.7727 8.80469 17.9681 8.84375 18.1634 8.84375C18.593 8.84375 18.9837 8.60937 19.218 8.21875L21.9524 3.02343C22.2649 2.4375 22.0305 1.73437 21.4446 1.46093C20.8587 1.1875 20.1555 1.38281 19.843 1.96875L19.8431 1.96875Z" fill={color} />
  </svg>
);

export default Cursor;

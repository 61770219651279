import styles from "../Icon.module.css";

import React from "react";

const CardInCircle = ({color="#ADB5BD"}) => (
  <svg className={`icon ${styles.cardInCircle}`} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill={color}/>
    <path d="M44.251 25.625V24.6875C44.251 22.4475 42.4285 20.625 40.1885 20.625H20.8135C18.5735 20.625 16.751 22.4475 16.751 24.6875V25.625H44.251ZM16.751 28.125V35.3125C16.751 37.5525 18.5735 39.375 20.8135 39.375H40.1885C42.4285 39.375 44.251 37.5525 44.251 35.3125V28.125H16.751ZM38.9385 35H35.1885C34.6704 35 34.251 34.5806 34.251 34.0625C34.251 33.5444 34.6704 33.125 35.1885 33.125H38.9385C39.4566 33.125 39.876 33.5444 39.876 34.0625C39.876 34.5806 39.4566 35 38.9385 35Z" fill="white"/>
  </svg>
);

export default CardInCircle;
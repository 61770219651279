import styles from "../Icon.module.css";

import React from "react";

const Folder = ({color='#FFFFFF'}) => (
  <svg className={`icon ${styles.folder}`} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M8 2H2C0.9 2 0 2.9 0 4V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V6C20 4.9 19.1 4 18 4H10L8 2Z' fill={color} />
  </svg>
);

export default Folder;

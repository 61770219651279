import styles from "../Icon.module.css";

import React from "react";

const Yandex = ({ color = "#FC3F1D" }) => (
  <svg
    className={`icon ${styles.yandex}`}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.0003 39.9593C31.0235 39.9593 39.9595 31.0233 39.9595 20C39.9595 8.97684 31.0235 0.0407715 20.0003 0.0407715C8.97708 0.0407715 0.0410156 8.97684 0.0410156 20C0.0410156 31.0233 8.97708 39.9593 20.0003 39.9593Z'
      fill={color}
    />
    <path
      d='M27.2227 32.5296H22.8413V10.8593H20.8894C17.3116 10.8593 15.4376 12.6482 15.4376 15.3185C15.4376 18.3481 16.7301 19.7518 19.4042 21.5407L21.6079 23.0259L15.2746 32.5259H10.5635L16.2635 24.0444C12.9857 21.7037 11.1413 19.4185 11.1413 15.563C11.1413 10.7444 14.5005 7.46667 20.8598 7.46667H27.1931V32.5222H27.2227V32.5296Z'
      fill='white'
    />
  </svg>
);

export default Yandex;

import styles from "../Icon.module.css";

import React from 'react';

const VkSquare = ({ color = '#2787F5' }) => (
  <svg className={`icon ${styles.vkSquare}`} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M10.4 0H19.6C28 0 30 2 30 10.4V19.6C30 28 28 30 19.6 30H10.4C2 30 0 28 0 19.6V10.4C0 2 2 0 10.4 0Z' fill={color} />
    <path fillRule='evenodd' clipRule='evenodd' d='M24.5678 10.3353C24.7068 9.87184 24.5678 9.53125 23.9063 9.53125H21.7188C21.1626 9.53125 20.9061 9.82547 20.7671 10.1499C20.7671 10.1499 19.6546 12.8614 18.0788 14.6227C17.5689 15.1325 17.3371 15.2947 17.059 15.2947C16.92 15.2947 16.7187 15.1325 16.7187 14.6691V10.3353C16.7187 9.77914 16.5573 9.53125 16.0938 9.53125H12.6563C12.3087 9.53125 12.0996 9.7894 12.0996 10.0341C12.0996 10.5613 12.8875 10.6829 12.9688 12.1662V15.3875C12.9688 16.0937 12.8412 16.2218 12.5631 16.2218C11.8215 16.2218 10.0177 13.4982 8.94778 10.3817C8.73812 9.77594 8.52782 9.53125 7.96876 9.53125H5.78126C5.15626 9.53125 5.03125 9.82547 5.03125 10.1499C5.03125 10.7293 5.77286 13.603 8.48431 17.4037C10.2919 19.9993 12.8388 21.4062 15.1563 21.4062C16.5467 21.4062 16.7188 21.0937 16.7188 20.5555V18.5938C16.7188 17.9688 16.8505 17.844 17.2908 17.844C17.6152 17.844 18.1715 18.0062 19.4693 19.2577C20.9525 20.7409 21.197 21.4062 22.0313 21.4062H24.2188C24.8438 21.4062 25.1563 21.0938 24.976 20.477C24.7787 19.8624 24.0706 18.9706 23.1309 17.9135C22.621 17.311 21.8563 16.6621 21.6245 16.3376C21.3001 15.9205 21.3928 15.7351 21.6245 15.3643C21.6245 15.3643 24.2897 11.6099 24.5678 10.3353Z' fill='white' />
  </svg>
);

export default VkSquare;

import styles from "../Icon.module.css";

import React from "react";

const DocumentBlank = ({ color = "#4D92DC" }) => (
  <svg
    className={`icon ${styles.documentBlank}`}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 17 21'
  >
    <g fill={color} fillRule='nonzero'>
      <path d='M16.713 4.25a.489.489 0 0 0-.144-.342L12.775.144a.496.496 0 0 0-.35-.144H1.885A1.04 1.04 0 0 0 .84 1.035v18.93c0 .57.468 1.035 1.044 1.035H15.67a1.04 1.04 0 0 0 1.043-1.035V4.255 4.25zM12.92 1.68l2.102 2.085h-2.05a.052.052 0 0 1-.052-.052V1.679zm2.803 18.286a.052.052 0 0 1-.052.052H1.885a.052.052 0 0 1-.052-.052V1.035c0-.028.023-.052.052-.052h10.044v2.729c0 .57.468 1.035 1.044 1.035h2.75v15.218z' />
      <path d='M13.235 7H4.32a.502.502 0 0 0-.504.5c0 .276.226.5.504.5h8.913a.502.502 0 0 0 .504-.5c0-.276-.225-.5-.503-.5zM13.235 10H4.32a.502.502 0 0 0-.504.5c0 .276.226.5.504.5h8.913a.502.502 0 0 0 .504-.5c0-.276-.225-.5-.503-.5zM13.235 13H4.32a.502.502 0 0 0-.504.5c0 .276.226.5.504.5h8.913a.502.502 0 0 0 .504-.5c0-.276-.225-.5-.503-.5zM9.221 16H4.366c-.303 0-.549.224-.549.5s.246.5.55.5H9.22c.303 0 .549-.224.549-.5s-.246-.5-.549-.5z' />
    </g>
  </svg>
);

export default DocumentBlank;

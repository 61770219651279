const ChatDotted = ({ color = "#4D92DC" }) => (
  <svg
    className='icon icon--chatDotted'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.9 0.999962C1.75684 0.999962 0 2.73532 0 4.85229V14.9276C0 17.0446 1.75684 18.7799 3.9 18.7799H4.8V22.0396C4.8 23.2047 6.25638 23.9236 7.2 23.2249L13.2 18.7799H20.1C22.2432 18.7799 24 17.0446 24 14.9276V4.85229C24 2.73532 22.2432 0.999962 20.1 0.999962H3.9ZM3.9 2.77796H20.1C21.27 2.77796 22.2 3.69655 22.2 4.85229V14.9276C22.2 16.0833 21.27 17.0019 20.1 17.0019H12.9C12.7051 17.002 12.5156 17.0646 12.3598 17.1802L6.6 21.4469V17.8909C6.59995 17.4 6.19704 17.002 5.7 17.0019H3.9C2.72996 17.0019 1.8 16.0833 1.8 14.9276V4.85229C1.8 3.69655 2.72996 2.77796 3.9 2.77796ZM7.8 8.70461C7.137 8.70461 6.6 9.23504 6.6 9.88994C6.6 10.5448 7.137 11.0753 7.8 11.0753C8.463 11.0753 9 10.5448 9 9.88994C9 9.23505 8.463 8.70461 7.8 8.70461ZM12 8.70461C11.337 8.70461 10.8 9.23504 10.8 9.88994C10.8 10.5448 11.337 11.0753 12 11.0753C12.663 11.0753 13.2 10.5448 13.2 9.88994C13.2 9.23505 12.663 8.70461 12 8.70461ZM16.2 8.70461C15.537 8.70461 15 9.23504 15 9.88994C15 10.5448 15.537 11.0753 16.2 11.0753C16.863 11.0753 17.4 10.5448 17.4 9.88994C17.4 9.23505 16.863 8.70461 16.2 8.70461Z'
      fill={color}
    />
  </svg>
);

export default ChatDotted;

import { useEffect, useState } from "react";
import styles from "./AuthPhoneTimer.module.css";

const AuthPhoneTimer = ({ time, handleClick }) => {
  const [timer, setTimer] = useState(time);

  const reloadTimer = () => {
    handleClick();
    setTimeout(() => {
      setTimer(time);
    }, 100);
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer, time]);
  if (timer > 0) {
    return (
      <div className={styles.auth_phone_timer}>
        {`Новый код можно получить через 00:${timer.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false
        })}`}
      </div>
    );
  }
  return (
    <div onClick={reloadTimer} className={styles.auth_phone_timer__newcode}>
      Отправить код повторно
    </div>
  );
};

export default AuthPhoneTimer;

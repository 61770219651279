import styles from "../Icon.module.css";

import React from "react";

const Heart = ({color='#FFFFFF'}) => (
  <svg className={`icon ${styles.heart}`} viewBox='0 0 30 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M22.0312 0.757812C20.4033 0.757812 18.9108 1.27367 17.5953 2.29109C16.3341 3.2665 15.4944 4.50887 15 5.41227C14.5056 4.50881 13.6659 3.2665 12.4047 2.29109C11.0892 1.27367 9.59666 0.757812 7.96875 0.757812C3.42586 0.757812 0 4.47365 0 9.40121C0 14.7247 4.274 18.3669 10.7443 23.8808C11.843 24.8172 13.0884 25.8785 14.3829 27.0105C14.5535 27.1599 14.7727 27.2422 15 27.2422C15.2273 27.2422 15.4465 27.1599 15.6171 27.0106C16.9117 25.8784 18.157 24.8171 19.2564 23.8802C25.726 18.3669 30 14.7247 30 9.40121C30 4.47365 26.5741 0.757812 22.0312 0.757812Z' fill={color} />
  </svg>
);

export default Heart;

import styles from "../Icon.module.css";

import React from "react";

const Hourglass = ({ color = "#4D92DC" }) => (
  <svg
    className={`icon ${styles.hourglass}`}
    viewBox='0 0 14 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13 3.62074V2.04138C13.4462 1.78126 13.75 1.30286 13.75 0.749988V0.375012C13.75 0.16773 13.5823 0 13.375 0H0.625012C0.41773 0 0.25 0.16773 0.25 0.375012V0.750023C0.25 1.30286 0.55382 1.78126 0.999988 2.04142V3.62074C0.999988 5.10683 1.63354 6.52918 2.73804 7.52309L4.37903 9L2.73804 10.4769C1.63354 11.4708 0.999988 12.8932 0.999988 14.3793V15.9586C0.55382 16.2187 0.25 16.6971 0.25 17.25V17.625C0.25 17.8323 0.41773 18 0.625012 18H13.375C13.5823 18 13.75 17.8323 13.75 17.625V17.25C13.75 16.6971 13.4462 16.2187 13 15.9586V14.3793C13 12.8932 12.3665 11.4708 11.262 10.4769L9.62097 9L11.262 7.52309C12.3665 6.52918 13 5.1068 13 3.62074ZM10.2586 6.40832L7.9983 8.44263C7.84009 8.58473 7.74999 8.78723 7.74999 9C7.74999 9.21277 7.84009 9.4153 7.9983 9.55737L10.2586 11.5917C11.0474 12.3018 11.5 13.318 11.5 14.3793V15.75H10.5623L7.29992 11.4002C7.15856 11.2112 6.84141 11.2112 6.70005 11.4002L3.43769 15.75H2.5V14.3793C2.5 13.318 2.95264 12.3017 3.74147 11.5916L6.00174 9.55733C6.15994 9.41523 6.25005 9.21273 6.25005 8.99996C6.25005 8.7872 6.15994 8.58466 6.00174 8.4426L3.74147 6.40828C2.95264 5.69823 2.5 4.682 2.5 3.62074V2.25H11.5V3.62074C11.5 4.682 11.0474 5.69823 10.2586 6.40832Z'
      fill={color}
    />
    <path
      d='M9.58447 5.25H4.41469C4.26636 5.25 4.13196 5.33754 4.07192 5.47303C4.01187 5.60891 4.03711 5.76711 4.1371 5.87698L6.74818 8.28592C6.81958 8.35075 6.90969 8.38295 6.99976 8.38295C7.08983 8.38295 7.17993 8.35071 7.25133 8.28592L9.86203 5.87698C9.96202 5.76711 9.98729 5.60891 9.92721 5.47303C9.8672 5.33754 9.7328 5.25 9.58447 5.25Z'
      fill={color}
    />
  </svg>
);

export default Hourglass;

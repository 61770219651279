import styles from "../Icon.module.css";

import React from 'react';

const Fb = ({ color = '#FFFFFF' }) => (
  <svg className={`icon ${styles.fb}`} viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10.7391 0.00416134L8.10068 0C5.13653 0 3.22097 1.9319 3.22097 4.92203V7.19141H0.568183C0.33895 7.19141 0.15332 7.37409 0.15332 7.59943V10.8875C0.15332 11.1128 0.339162 11.2953 0.568183 11.2953H3.22097V19.5922C3.22097 19.8175 3.4066 20 3.63583 20H7.09697C7.3262 20 7.51183 19.8173 7.51183 19.5922V11.2953H10.6136C10.8428 11.2953 11.0284 11.1128 11.0284 10.8875L11.0297 7.59943C11.0297 7.49123 10.9859 7.38762 10.9082 7.31105C10.8305 7.23448 10.7247 7.19141 10.6146 7.19141H7.51183V5.26763C7.51183 4.34298 7.73598 3.87358 8.96131 3.87358L10.7387 3.87295C10.9677 3.87295 11.1533 3.69027 11.1533 3.46514V0.411972C11.1533 0.187052 10.9679 0.00457747 10.7391 0.00416134Z' fill={color} />
  </svg>
);

export default Fb;

import isWindowDefined from "./isWindowDefined";

const returnCookieValue = (value) => {
  let cookies = isWindowDefined() ? document.cookie : "";

  if (cookies.includes(";")) {
    const parsed = cookies.split(";");
    cookies = parsed.find((substring) => substring.includes(value));
  }

  if (cookies && cookies.replace(/\s/g, "").split("=")[0] === value) return cookies.split("=")[1];
  return undefined;
};

export default returnCookieValue;

import styles from "../Icon.module.css";

const Bell = () => (
  <svg
    className={`icon ${styles.bell}`}
    viewBox='0 0 54 54'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_27439_249250)'>
      <path
        opacity='0.4'
        d='M13.9836 22.8346C13.9836 15.3795 20.0272 9.33594 27.4823 9.33594C34.4048 9.33594 40.0167 14.9478 40.0167 21.8704V40.0178H13.9836V22.8346Z'
        fill='white'
      />
      <path
        d='M45.5863 5.16797C47.216 5.16797 48.5365 6.48847 48.5365 8.11814C48.5365 9.74782 47.216 11.0683 45.5863 11.0683C43.9566 11.0683 42.6361 9.74782 42.6361 8.11814C42.6361 6.48847 43.9566 5.16797 45.5863 5.16797Z'
        fill='#D0E0F1'
      />
      <path
        d='M6.05389 39.9808C8.0091 39.9808 9.59411 38.3958 9.59411 36.4406C9.59411 34.4854 8.0091 32.9004 6.05389 32.9004C4.09868 32.9004 2.51367 34.4854 2.51367 36.4406C2.51367 38.3958 4.09868 39.9808 6.05389 39.9808Z'
        fill='#D0E0F1'
      />
      <path
        d='M30.2452 45.291V46.4711C30.2452 48.1008 28.9247 49.4213 27.295 49.4213C25.6653 49.4213 24.3448 48.1008 24.3448 46.4711V45.291H30.2452Z'
        fill='#D0E0F1'
      />
      <path
        d='M40.2759 40.5703C41.5799 40.5703 42.636 41.6265 42.636 42.9305C42.636 44.2331 41.5786 45.2906 40.2759 45.2906C37.6367 45.2906 17.0714 45.2906 14.3144 45.2906C13.0104 45.2906 11.9542 44.2344 11.9542 42.9305C11.9542 41.6278 13.0116 40.5703 14.3144 40.5703H40.2759Z'
        fill='#D0E0F1'
      />
      <path d='M40.2762 25.2305H14.3146V29.3607H40.2762V25.2305Z' fill='#D0E0F1' />
      <path
        d='M51.1916 45.8814C51.6804 45.8814 52.0766 45.4852 52.0766 44.9964C52.0766 44.5076 51.6804 44.1113 51.1916 44.1113C50.7028 44.1113 50.3065 44.5076 50.3065 44.9964C50.3065 45.4852 50.7028 45.8814 51.1916 45.8814Z'
        fill='#4D92DC'
      />
      <path
        d='M48.2413 21.6881C48.7301 21.6881 49.1263 21.2918 49.1263 20.803C49.1263 20.3142 48.7301 19.918 48.2413 19.918C47.7525 19.918 47.3562 20.3142 47.3562 20.803C47.3562 21.2918 47.7525 21.6881 48.2413 21.6881Z'
        fill='#4D92DC'
      />
      <path
        d='M39.9808 4.58066C40.4696 4.58066 40.8658 4.1844 40.8658 3.6956C40.8658 3.2068 40.4696 2.81055 39.9808 2.81055C39.492 2.81055 39.0957 3.2068 39.0957 3.6956C39.0957 4.1844 39.492 4.58066 39.9808 4.58066Z'
        fill='#4D92DC'
      />
      <path
        d='M18.7397 51.7818C19.2285 51.7818 19.6247 51.3856 19.6247 50.8968C19.6247 50.408 19.2285 50.0117 18.7397 50.0117C18.2509 50.0117 17.8546 50.408 17.8546 50.8968C17.8546 51.3856 18.2509 51.7818 18.7397 51.7818Z'
        fill='#4D92DC'
      />
      <path
        d='M11.6592 3.98886C12.148 3.98886 12.5443 3.59261 12.5443 3.1038C12.5443 2.615 12.148 2.21875 11.6592 2.21875C11.1704 2.21875 10.7742 2.615 10.7742 3.1038C10.7742 3.59261 11.1704 3.98886 11.6592 3.98886Z'
        fill='#4D92DC'
      />
      <path
        d='M6.93901 47.0611C7.42781 47.0611 7.82407 46.6649 7.82407 46.1761C7.82407 45.6873 7.42781 45.291 6.93901 45.291C6.45021 45.291 6.05396 45.6873 6.05396 46.1761C6.05396 46.6649 6.45021 47.0611 6.93901 47.0611Z'
        fill='#4D92DC'
      />
      <path
        d='M5.75896 17.5592C6.24776 17.5592 6.64401 17.1629 6.64401 16.6741C6.64401 16.1853 6.24776 15.7891 5.75896 15.7891C5.27015 15.7891 4.8739 16.1853 4.8739 16.6741C4.8739 17.1629 5.27015 17.5592 5.75896 17.5592Z'
        fill='#4D92DC'
      />
      <path
        d='M40.2757 41.1609C39.9498 41.1609 39.6857 40.8968 39.6857 40.5709V22.2798C39.6857 18.9691 38.3968 15.8577 36.0565 13.5186C33.717 11.1779 30.6055 9.88905 27.2949 9.88905C20.4626 9.88905 14.9042 15.4475 14.9042 22.2798V40.5709C14.9042 40.8968 14.64 41.1609 14.3142 41.1609C13.9883 41.1609 13.7241 40.8968 13.7241 40.5709V22.2798C13.7241 14.7968 19.812 8.70898 27.2949 8.70898C30.9208 8.70898 34.3286 10.1207 36.8908 12.6842C39.454 15.246 40.8657 18.6538 40.8657 22.2798V40.5709C40.8657 40.8968 40.6016 41.1609 40.2757 41.1609H40.2757Z'
        fill='#4D92DC'
      />
      <path
        d='M36.1457 21.6886C35.8198 21.6886 35.5557 21.4244 35.5557 21.0986C35.5557 17.7176 31.9442 13.4281 27.8852 13.4281C27.5593 13.4281 27.2952 13.1639 27.2952 12.8381C27.2952 12.5123 27.5593 12.248 27.8852 12.248C30.1554 12.248 32.3839 13.308 34.1599 15.2327C35.7728 16.9805 36.7357 19.1734 36.7357 21.0986C36.7357 21.4244 36.4716 21.6886 36.1457 21.6886H36.1457Z'
        fill='#4D92DC'
      />
      <path
        d='M40.276 45.8808H30.2454C29.9194 45.8808 29.6553 45.6167 29.6553 45.2908C29.6553 44.9649 29.9194 44.7008 30.2454 44.7008H40.276C40.7485 44.7008 41.1929 44.5165 41.5273 44.182C41.8617 43.8474 42.0461 43.403 42.0461 42.9306C42.0461 41.9545 41.252 41.1605 40.276 41.1605H14.3144C13.8419 41.1605 13.3975 41.3447 13.063 41.6793C12.7284 42.0138 12.5443 42.4584 12.5443 42.9306C12.5443 43.9068 13.3384 44.7008 14.3144 44.7008H24.345C24.6708 44.7008 24.9351 44.9648 24.9351 45.2908C24.9351 45.6167 24.6708 45.8808 24.345 45.8808H14.3144C12.6877 45.8808 11.3643 44.5574 11.3643 42.9306C11.3643 42.1431 11.6712 41.4023 12.2285 40.8449C12.786 40.2874 13.5267 39.9805 14.3144 39.9805H40.276C41.9027 39.9805 43.2261 41.3039 43.2261 42.9306C43.2261 43.7183 42.9191 44.4591 42.3617 45.0164C41.8044 45.5738 41.0637 45.8808 40.276 45.8808Z'
        fill='#4D92DC'
      />
      <path
        d='M27.2951 50.0115C25.343 50.0115 23.7549 48.4234 23.7549 46.4713V45.2912C23.7549 44.9653 24.0191 44.7012 24.3449 44.7012H30.2453C30.5712 44.7012 30.8353 44.9653 30.8353 45.2912V46.4713C30.8353 48.4234 29.2472 50.0115 27.2951 50.0115ZM24.935 45.8812V46.4713C24.935 47.7727 25.9937 48.8314 27.2951 48.8314C28.5965 48.8314 29.6552 47.7727 29.6552 46.4713V45.8812H24.935Z'
        fill='#4D92DC'
      />
      <path
        d='M27.2951 9.89097C25.343 9.89097 23.7549 8.30283 23.7549 6.35076C23.7549 4.39869 25.343 2.81055 27.2951 2.81055C29.2472 2.81055 30.8353 4.39868 30.8353 6.35076C30.8353 8.30283 29.2472 9.89097 27.2951 9.89097ZM27.2951 3.99062C25.9937 3.99062 24.935 5.04938 24.935 6.35076C24.935 7.65214 25.9937 8.7109 27.2951 8.7109C28.5965 8.7109 29.6552 7.65214 29.6552 6.35076C29.6552 5.04938 28.5965 3.99062 27.2951 3.99062Z'
        fill='#4D92DC'
      />
      <path
        d='M40.2757 25.8187H14.3142C13.9883 25.8187 13.7241 25.5545 13.7241 25.2287C13.7241 24.9029 13.9883 24.6387 14.3142 24.6387H40.2757C40.6016 24.6387 40.8657 24.9029 40.8657 25.2287C40.8657 25.5545 40.6016 25.8187 40.2757 25.8187Z'
        fill='#4D92DC'
      />
      <path
        d='M40.2757 29.9516H14.3142C13.9883 29.9516 13.7241 29.6875 13.7241 29.3615C13.7241 29.0356 13.9883 28.7715 14.3142 28.7715H40.2757C40.6016 28.7715 40.8657 29.0356 40.8657 29.3615C40.8657 29.6875 40.6016 29.9516 40.2757 29.9516Z'
        fill='#4D92DC'
      />
      <path
        d='M26.1149 33.4887H14.3142C13.9883 33.4887 13.7241 33.2246 13.7241 32.8986C13.7241 32.5727 13.9883 32.3086 14.3142 32.3086H26.1149C26.4407 32.3086 26.7049 32.5727 26.7049 32.8986C26.7049 33.2246 26.4407 33.4887 26.1149 33.4887Z'
        fill='#4D92DC'
      />
      <path
        d='M45.5862 11.6585C43.6342 11.6585 42.046 10.0704 42.046 8.11834C42.046 6.16627 43.6342 4.57812 45.5862 4.57812C47.5383 4.57812 49.1264 6.16626 49.1264 8.11834C49.1264 10.0704 47.5383 11.6585 45.5862 11.6585ZM45.5862 5.7582C44.2849 5.7582 43.2261 6.81696 43.2261 8.11834C43.2261 9.41972 44.2849 10.4785 45.5862 10.4785C46.8876 10.4785 47.9464 9.41972 47.9464 8.11834C47.9464 6.81696 46.8876 5.7582 45.5862 5.7582Z'
        fill='#4D92DC'
      />
      <path
        d='M6.05383 40.5691C3.77641 40.5691 1.92358 38.7162 1.92358 36.4388C1.92358 34.1614 3.77641 32.3086 6.05383 32.3086C8.33125 32.3086 10.1841 34.1614 10.1841 36.4388C10.1841 38.7163 8.33124 40.5691 6.05383 40.5691ZM6.05383 33.4887C4.4271 33.4887 3.10365 34.8121 3.10365 36.4388C3.10365 38.0656 4.4271 39.389 6.05383 39.389C7.68055 39.389 9.004 38.0656 9.004 36.4388C9.004 34.8121 7.68055 33.4887 6.05383 33.4887Z'
        fill='#4D92DC'
      />
    </g>
    <defs>
      <clipPath id='clip0_27439_249250'>
        <rect width='54' height='54' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Bell;

import styles from "../Icon.module.css";

import React from "react";

const SendInCircle = ({color="#4D92DC"}) => (
  <svg className={`icon ${styles.sendInCircle}`} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="59" height="59" rx="29.5" fill="white"/>
    <path d="M15.8867 28.1198C24.5027 24.3294 35.6526 19.7081 37.1916 19.0684C41.2288 17.3943 42.4676 17.7152 41.8499 21.4226C41.4064 24.0874 40.1265 32.9097 39.1064 38.401C38.501 41.6574 37.1429 42.0434 35.0075 40.6345C33.9805 39.9565 28.797 36.5288 27.672 35.7239C26.6451 34.9904 25.2287 34.1081 27.005 32.3702C27.6371 31.7512 31.7806 27.7948 35.0088 24.7153C35.4318 24.3109 34.9005 23.6466 34.4124 23.9708C30.0612 26.8563 24.0283 30.8613 23.2605 31.383C22.1006 32.1707 20.9865 32.532 18.9868 31.9575C17.4758 31.5236 15.9998 31.006 15.4253 30.8085C13.2131 30.0489 13.7382 29.0652 15.8867 28.1198V28.1198Z" fill={color}/>
  </svg>
);

export default SendInCircle;

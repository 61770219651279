import styles from "../Icon.module.css";

const Ruble = ({ color = "#777777" }) => (
  <svg
    className={`icon ${styles.sandglassInCircle}`}
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle opacity='0.1' cx='30' cy='30' r='30' fill={color} />
    <path
      d='M20.88 18.4805V20.4005C20.88 21.1898 21.5306 21.8405 22.32 21.8405H23.28V25.0655C23.28 26.5092 23.9981 27.8648 25.2 28.6655L27.21 30.0005L25.2 31.3355C23.9981 32.1361 23.28 33.4917 23.28 34.9355V38.1605H22.32C21.5306 38.1605 20.88 38.8111 20.88 39.6005V41.5205H39.12V39.6005C39.12 38.8111 38.4694 38.1605 37.68 38.1605H36.72V34.9355C36.72 33.4917 36.0019 32.1361 34.8 31.3355L32.79 30.0005L34.8 28.6655C36.0019 27.8648 36.72 26.5092 36.72 25.0655V21.8405H37.68C38.4694 21.8405 39.12 21.1898 39.12 20.4005V18.4805H20.88ZM21.84 19.4405H38.16V20.4005C38.16 20.6723 37.9519 20.8805 37.68 20.8805H22.32C22.0481 20.8805 21.84 20.6723 21.84 20.4005V19.4405ZM24.24 21.8405H35.76V25.0655C35.76 26.1886 35.1956 27.233 34.26 27.8555L31.65 29.5955L31.05 30.0005L31.65 30.4055L34.26 32.1455C35.1956 32.7698 35.76 33.8123 35.76 34.9355V38.1605H34.77C34.425 34.2567 30 32.7755 30 32.7755C30 32.7755 25.5769 34.3317 25.23 38.1605H24.24V34.9355C24.24 33.8123 24.8044 32.7698 25.74 32.1455L28.35 30.4055L28.95 30.0005L28.35 29.5955L25.74 27.8555C24.8044 27.233 24.24 26.1886 24.24 25.0655V21.8405ZM22.32 39.1205H37.68C37.9519 39.1205 38.16 39.3286 38.16 39.6005V40.5605H21.84V39.6005C21.84 39.3286 22.0481 39.1205 22.32 39.1205Z'
      fill={color}
    />
  </svg>
);

export default Ruble;

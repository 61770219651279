export default () => (
  <svg
    className='icon icon--green-tick'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle opacity='0.1' cx='30' cy='30' r='30' fill='#1EC28C' />
    <path
      d='M22 30.1877L27.1982 35L38 25'
      stroke='#1EC28C'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

import Airplane from "./Airplane";
import Bag from "./Bag";
import Banckruptcy from "./Banckruptcy";
import Bell from "./Bell";
import BlankPageWithMessage from "./BlankPageWithMessage";
import BlankPageWithPen from "./BlankPageWithPen";
import Building from "./Building";
import Car from "./Car";
import CardInCircle from "./CardInCircle";
import Chat from "./Chat";
import ChatDotted from "./ChatDotted";
import ChatInCircle from "./ChatInCircle";
import ChatWithDots from "./ChatWithDots";
import ChatWithShadow from "./ChatWithShadow";
import ChevronDown from "./ChevronDown";
import Clap from "./Clap";
import Clip from "./Clip";
import Coins from "./Coins";
import Consult from "./Consult";
import Cursor from "./Cursor";
import DocTick from "./DocTick";
import DocumentBlank from "./DocumentBlank";
import DocumentScan from "./DocumentScan";
import DocumentWithMessage from "./DocumentWithMessage";
import DocumentWithPen from "./DocumentWithPen";
import DocumentWithPenInCirlce from "./DocumentWithPenInCirlce";
import DocumentWithTick from "./DocumentWithTick";
import Download from "./Download";
import Fb from "./Fb";
import File from "./File";
import Flash from "./Flash";
import FluencyChatBubbles from "./FluencyChatBubbles";
import FluencyDocument from "./FluencyDocument";
import FluencyGavel from "./FluencyGavel";
import Folder from "./Folder";
import Follow from "./Follow";
import Gear from "./Gear";
import Google from "./Google";
import GreenTick from "./GreenTick";
import HandWriting from "./HandWriting";
import Hat from "./Hat";
import HatInCircle from "./HatInCircle";
import Heart from "./Heart";
import Home from "./Home";
import Hourglass from "./Hourglass";
import Info from "./Info";
import Instagram from "./Instagram";
import Login from "./Login";
import MailDocument from "./MailDocument";
import MailWithTick from "./MailWithTick";
import Mask from "./Mask";
import Moon from "./Moon";
import Other from "./Other";
import Phone from "./Phone";
import Profile from "./Profile";
import Ruble from "./Ruble";
import RubleCirle from "./RubleCircle";
import SandglassInCircle from "./SandglassInCircle";
import Scales from "./Scales";
import Search from "./Search";
import Send from "./Send";
import SendInCircle from "./SendInCircle";
import Shape from "./Shape";
import Share from "./Share";
import ShieldWithTick from "./ShieldWithTick";
import Shirt from "./Shirt";
import Sign from "./Sign";
import SquaresInCircle from "./SquaresInCircle";
import Telegram from "./Telegram";
import Ticket from "./Ticket";
import TickInCircle from "./TickInCircle";
import Tie from "./Tie";
import Vk from "./Vk";
import VkSquare from "./VkSquare";
import Yandex from "./Yandex";
import Zen from "./Zen";
import Zoon from "./Zoon";

export default {
  airplane: Airplane,
  bag: Bag,
  banckruptcy: Banckruptcy,
  bell: Bell,
  "blank-page-with-message": BlankPageWithMessage,
  "blank-page-with-pen": BlankPageWithPen,
  building: Building,
  car: Car,
  "card-in-circle": CardInCircle,
  chat: Chat,
  "chat-dotted": ChatDotted,
  "chat-in-circle": ChatInCircle,
  "chat-with-dots": ChatWithDots,
  "chat-with-shadow": ChatWithShadow,
  "chevron-down": ChevronDown,
  clap: Clap,
  clip: Clip,
  coins: Coins,
  consult: Consult,
  cursor: Cursor,
  "doc-tick": DocTick,
  "document-blank": DocumentBlank,
  "document-scan": DocumentScan,
  "document-with-message": DocumentWithMessage,
  "document-with-pen": DocumentWithPen,
  "document-with-pen-in-cirlce": DocumentWithPenInCirlce,
  "document-with-tick": DocumentWithTick,
  download: Download,
  fb: Fb,
  file: File,
  flash: Flash,
  fluencyChatBubbles: FluencyChatBubbles,
  fluencyDocument: FluencyDocument,
  fluencyGavel: FluencyGavel,
  folder: Folder,
  follow: Follow,
  gear: Gear,
  google: Google,
  "green-tick": GreenTick,
  "hand-writing": HandWriting,
  hat: Hat,
  "hat-in-circle": HatInCircle,
  heart: Heart,
  home: Home,
  hourglass: Hourglass,
  info: Info,
  instagram: Instagram,
  login: Login,
  "mail-document": MailDocument,
  "mail-with-tick": MailWithTick,
  mask: Mask,
  moon: Moon,
  other: Other,
  phone: Phone,
  profile: Profile,
  ruble: Ruble,
  "ruble-circle": RubleCirle,
  "sandglass-in-circle": SandglassInCircle,
  scales: Scales,
  search: Search,
  send: Send,
  "send-in-circle": SendInCircle,
  shape: Shape,
  share: Share,
  "shield-with-tick": ShieldWithTick,
  shirt: Shirt,
  sign: Sign,
  "squares-in-circle": SquaresInCircle,
  telegram: Telegram,
  "tick-in-circle": TickInCircle,
  ticket: Ticket,
  tie: Tie,
  vk: Vk,
  "vk-square": VkSquare,
  yandex: Yandex,
  zen: Zen,
  zoon: Zoon
};

import * as React from "react";

const RubleCirle = ({ color = "#4D92DC" }) => (
  <svg viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17 .5C7.626.5 0 8.126 0 17.5c0 9.374 7.626 17 17 17 9.374 0 17-7.626 17-17 0-9.374-7.626-17-17-17Zm0 2.55A14.43 14.43 0 0 1 31.45 17.5 14.43 14.43 0 0 1 17 31.95 14.43 14.43 0 0 1 2.55 17.5 14.43 14.43 0 0 1 17 3.05ZM13.175 9c-.704 0-1.275.57-1.275 1.275v6.375h-1.275a1.275 1.275 0 1 0 0 2.55H11.9v1.7h-1.275a1.275 1.275 0 1 0 0 2.55H11.9v2.125a1.275 1.275 0 1 0 2.55 0V23.45h5.525a1.275 1.275 0 1 0 0-2.55H14.45v-1.7h5.1c2.802 0 5.1-2.299 5.1-5.1 0-2.801-2.298-5.1-5.1-5.1h-6.375Zm1.275 2.55h5.1a2.531 2.531 0 0 1 2.55 2.55 2.531 2.531 0 0 1-2.55 2.55h-5.1v-5.1Z'
      fill={color}
    />
  </svg>
);

export default RubleCirle;

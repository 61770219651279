import styles from "../Icon.module.css";

import React from "react";

const Building = ({color='#FFFFFF'}) => (
  <svg className={`icon ${styles.building}`} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16.2875 2.33748L2.55 0.249976C1.9125 0.149976 1.2625 0.324976 0.775 0.737476C0.2875 1.16248 0 1.77498 0 2.41248V28.75C0 29.4375 0.5625 30 1.25 30H5.3125V27.4375C5.3125 26.225 6.2875 25.25 7.5 25.25H10.625C11.8375 25.25 12.8125 26.225 12.8125 27.4375V30H18.125V4.49998C18.125 3.42498 17.35 2.51248 16.2875 2.33748ZM6.875 21.4375H5C4.4825 21.4375 4.0625 21.0175 4.0625 20.5C4.0625 19.9825 4.4825 19.5625 5 19.5625H6.875C7.3925 19.5625 7.8125 19.9825 7.8125 20.5C7.8125 21.0175 7.3925 21.4375 6.875 21.4375ZM6.875 16.6875H5C4.4825 16.6875 4.0625 16.2675 4.0625 15.75C4.0625 15.2325 4.4825 14.8125 5 14.8125H6.875C7.3925 14.8125 7.8125 15.2325 7.8125 15.75C7.8125 16.2675 7.3925 16.6875 6.875 16.6875ZM6.875 11.9375H5C4.4825 11.9375 4.0625 11.5175 4.0625 11C4.0625 10.4825 4.4825 10.0625 5 10.0625H6.875C7.3925 10.0625 7.8125 10.4825 7.8125 11C7.8125 11.5175 7.3925 11.9375 6.875 11.9375ZM6.875 7.18748H5C4.4825 7.18748 4.0625 6.76748 4.0625 6.24998C4.0625 5.73248 4.4825 5.31248 5 5.31248H6.875C7.3925 5.31248 7.8125 5.73248 7.8125 6.24998C7.8125 6.76748 7.3925 7.18748 6.875 7.18748ZM13.125 21.4375H11.25C10.7325 21.4375 10.3125 21.0175 10.3125 20.5C10.3125 19.9825 10.7325 19.5625 11.25 19.5625H13.125C13.6425 19.5625 14.0625 19.9825 14.0625 20.5C14.0625 21.0175 13.6425 21.4375 13.125 21.4375ZM13.125 16.6875H11.25C10.7325 16.6875 10.3125 16.2675 10.3125 15.75C10.3125 15.2325 10.7325 14.8125 11.25 14.8125H13.125C13.6425 14.8125 14.0625 15.2325 14.0625 15.75C14.0625 16.2675 13.6425 16.6875 13.125 16.6875ZM13.125 11.9375H11.25C10.7325 11.9375 10.3125 11.5175 10.3125 11C10.3125 10.4825 10.7325 10.0625 11.25 10.0625H13.125C13.6425 10.0625 14.0625 10.4825 14.0625 11C14.0625 11.5175 13.6425 11.9375 13.125 11.9375ZM13.125 7.18748H11.25C10.7325 7.18748 10.3125 6.76748 10.3125 6.24998C10.3125 5.73248 10.7325 5.31248 11.25 5.31248H13.125C13.6425 5.31248 14.0625 5.73248 14.0625 6.24998C14.0625 6.76748 13.6425 7.18748 13.125 7.18748Z' fill={color} />
    <path d='M28.275 13.5525L19.375 11.6888V30H27.8125C29.0187 30 30 29.0188 30 27.8125V15.69C30 14.6575 29.2962 13.7788 28.275 13.5525ZM25.3125 23.5H23.4375C22.92 23.5 22.5 23.08 22.5 22.5625C22.5 22.045 22.92 21.625 23.4375 21.625H25.3125C25.83 21.625 26.25 22.045 26.25 22.5625C26.25 23.08 25.83 23.5 25.3125 23.5ZM25.3125 18.75H23.4375C22.92 18.75 22.5 18.33 22.5 17.8125C22.5 17.295 22.92 16.875 23.4375 16.875H25.3125C25.83 16.875 26.25 17.295 26.25 17.8125C26.25 18.33 25.83 18.75 25.3125 18.75Z' fill={color} />
  </svg>
);

export default Building;

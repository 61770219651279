import styles from "../Icon.module.css";

const Zen = ({ color = "#FFFFFF" }) => (
  <svg
    className={`icon ${styles.zen}`}
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.0427 0C5.92094 0.000391797 3.06488 0.0141048 1.59884 1.44573C0.167292 2.91144 0.153576 5.90163 0.153576 9.88935C4.58148 9.75573 6.60634 9.64486 8.2213 8.06786C9.79821 6.4529 9.90912 4.42809 10.0427 0ZM1.59859 18.5539C0.167036 17.0882 0.15332 14.098 0.15332 10.1103C4.58123 10.2439 6.60609 10.3548 8.22105 11.9318C9.79796 13.5467 9.90887 15.5715 10.0425 19.9992C5.92069 19.9992 3.06463 19.9859 1.59859 18.5539ZM10.2635 19.9992C10.3972 15.5715 10.5081 13.5467 12.085 11.9318C13.6999 10.3548 15.7248 10.2439 20.1527 10.1103C20.1523 14.098 20.1386 17.0882 18.707 18.5539C17.241 19.9859 14.3854 19.9992 10.2635 19.9992ZM10.2635 0C10.3972 4.42809 10.5081 6.4529 12.085 8.06786C13.6999 9.64486 15.7248 9.75573 20.1527 9.88935C20.1523 5.90163 20.1386 2.91144 18.707 1.44573C17.241 0.0141048 14.3854 0.000391797 10.2635 0Z'
      fill={color}
    />
  </svg>
);

export default Zen;

import styles from "../Icon.module.css";

const ChatWithDots = ({ color = "#4D92DC" }) => (
  <svg
    className={`icon ${styles.chatWithDots}`}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.925 0.75C1.31763 0.75 0 2.05152 0 3.63924V11.1957C0 12.7834 1.31763 14.085 2.925 14.085H3.6V16.5297C3.6 17.4036 4.69228 17.9427 5.4 17.4187L9.9 14.085H15.075C16.6824 14.085 18 12.7834 18 11.1957V3.63924C18 2.05152 16.6824 0.75 15.075 0.75H2.925ZM2.925 2.0835H15.075C15.9525 2.0835 16.65 2.77244 16.65 3.63924V11.1957C16.65 12.0625 15.9525 12.7515 15.075 12.7515H9.675C9.52885 12.7515 9.38667 12.7985 9.26982 12.8852L4.95 16.0852V13.4182C4.94996 13.05 4.64778 12.7515 4.275 12.7515H2.925C2.04747 12.7515 1.35 12.0625 1.35 11.1957V3.63924C1.35 2.77244 2.04747 2.0835 2.925 2.0835ZM5.85 6.52849C5.35275 6.52849 4.95 6.92631 4.95 7.41748C4.95 7.90865 5.35275 8.30648 5.85 8.30648C6.34725 8.30648 6.75 7.90866 6.75 7.41748C6.75 6.92631 6.34725 6.52849 5.85 6.52849ZM9 6.52849C8.50275 6.52849 8.1 6.92631 8.1 7.41748C8.1 7.90865 8.50275 8.30648 9 8.30648C9.49725 8.30648 9.9 7.90866 9.9 7.41748C9.9 6.92631 9.49725 6.52849 9 6.52849ZM12.15 6.52849C11.6528 6.52849 11.25 6.92631 11.25 7.41748C11.25 7.90865 11.6527 8.30648 12.15 8.30648C12.6473 8.30648 13.05 7.90866 13.05 7.41748C13.05 6.92631 12.6473 6.52849 12.15 6.52849Z'
      fill={color}
    />
  </svg>
);

export default ChatWithDots;

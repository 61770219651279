import React from "react";

const MailDocument = (): React.ReactElement => {
  return (
    <svg
      className={`icon`}
      width='113'
      height='100'
      viewBox='0 0 113 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M110.697 37.8511H110.658L88.2221 47.4314L57.5249 60.5393C57.405 60.5898 57.2762 60.6161 57.1461 60.6168C57.016 60.6174 56.887 60.5924 56.7665 60.5432L25.0961 47.404L2.1088 37.8667L2.07369 37.8511H2.03456C1.67185 37.8515 1.32411 37.9957 1.06764 38.2522C0.811168 38.5087 0.666906 38.8564 0.666504 39.2191V98.6319C0.666907 98.9946 0.811169 99.3423 1.06764 99.5988C1.32411 99.8552 1.67185 99.9995 2.03456 99.9999H110.697C111.06 99.9995 111.408 99.8553 111.664 99.5988C111.921 99.3423 112.065 98.9946 112.065 98.6319V39.2191C112.065 38.8564 111.921 38.5087 111.664 38.2522C111.408 37.9957 111.06 37.8515 110.697 37.8511Z'
        fill='white'
      />
      <path
        d='M111.088 38.242C111.048 38.242 111.009 38.2298 110.976 38.207L56.9265 0.565219C56.762 0.451201 56.5666 0.390268 56.3665 0.39061C56.1663 0.390951 55.9711 0.45255 55.807 0.567128L2.14659 38.2066C2.12557 38.2214 2.10186 38.2318 2.0768 38.2374C2.05174 38.243 2.02583 38.2436 2.00055 38.2391C1.97527 38.2347 1.9511 38.2253 1.92944 38.2115C1.90778 38.1978 1.88905 38.1799 1.87431 38.1588C1.85957 38.1378 1.84911 38.1141 1.84353 38.089C1.83796 38.064 1.83737 38.0381 1.84181 38.0128C1.84625 37.9875 1.85562 37.9634 1.86939 37.9417C1.88317 37.92 1.90108 37.9013 1.9221 37.8866L55.5825 0.247062C55.8123 0.086677 56.0856 0.000462329 56.3658 1.85422e-06C56.646 -0.000458621 56.9197 0.0848565 57.1499 0.244486L111.2 37.8862C111.234 37.91 111.259 37.944 111.273 37.9834C111.286 38.0228 111.287 38.0655 111.274 38.1052C111.262 38.1449 111.237 38.1795 111.204 38.2042C111.17 38.2288 111.129 38.242 111.088 38.242L111.088 38.242Z'
        fill='#3F3D56'
      />
      <path
        d='M1.94385 38.0698L56.3658 0L110.788 38.0698L57.1323 60.8095L34.1371 51.3559L1.94385 38.0698Z'
        fill='#F4F4F4'
      />
      <path
        d='M5.21387 39.0653L56.4204 1.11487L108.016 41.7657L59.0588 70.788L32.4794 64.7295L5.21387 39.0653Z'
        fill='#E6E6E6'
      />
      <path
        d='M34.7225 89.2248H9.4251C9.26976 89.225 9.11591 89.1946 8.97234 89.1353C8.82878 89.076 8.69831 88.989 8.5884 88.8792C8.47849 88.7694 8.3913 88.6391 8.33181 88.4956C8.27232 88.3521 8.2417 88.1983 8.2417 88.043C8.2417 87.8876 8.27232 87.7338 8.33181 87.5903C8.3913 87.4468 8.47849 87.3165 8.5884 87.2067C8.69831 87.0969 8.82878 87.0099 8.97234 86.9506C9.11591 86.8913 9.26976 86.8609 9.4251 86.8611H34.7225C34.8778 86.8609 35.0317 86.8913 35.1752 86.9506C35.3188 87.0099 35.4493 87.0969 35.5592 87.2067C35.6691 87.3165 35.7563 87.4468 35.8158 87.5903C35.8752 87.7338 35.9059 87.8876 35.9059 88.043C35.9059 88.1983 35.8752 88.3521 35.8158 88.4956C35.7563 88.6391 35.6691 88.7694 35.5592 88.8792C35.4493 88.989 35.3188 89.076 35.1752 89.1353C35.0317 89.1946 34.8778 89.225 34.7225 89.2248Z'
        fill='#4D92DC'
      />
      <path
        d='M18.5012 84.1434H9.4251C9.26976 84.1436 9.11591 84.1132 8.97234 84.0539C8.82878 83.9946 8.69831 83.9076 8.5884 83.7978C8.47849 83.688 8.3913 83.5577 8.33181 83.4142C8.27232 83.2707 8.2417 83.1169 8.2417 82.9615C8.2417 82.8062 8.27232 82.6524 8.33181 82.5089C8.3913 82.3654 8.47849 82.2351 8.5884 82.1253C8.69831 82.0155 8.82878 81.9285 8.97234 81.8692C9.11591 81.8099 9.26976 81.7795 9.4251 81.7797H18.5012C18.6566 81.7795 18.8104 81.8099 18.954 81.8692C19.0976 81.9285 19.228 82.0155 19.3379 82.1253C19.4478 82.2351 19.535 82.3654 19.5945 82.5089C19.654 82.6524 19.6846 82.8062 19.6846 82.9615C19.6846 83.1169 19.654 83.2707 19.5945 83.4142C19.535 83.5577 19.4478 83.688 19.3379 83.7978C19.228 83.9076 19.0976 83.9946 18.954 84.0539C18.8104 84.1132 18.6566 84.1436 18.5012 84.1434Z'
        fill='#4D92DC'
      />
      <path
        d='M57.14 60.7144C56.9478 60.7146 56.7573 60.6772 56.5793 60.6045L24.9985 47.5007V6.48366C24.999 6.12096 25.1432 5.77323 25.3997 5.51676C25.6562 5.26029 26.0039 5.11602 26.3666 5.1156H86.9519C87.3146 5.11602 87.6624 5.26029 87.9188 5.51676C88.1753 5.77323 88.3196 6.12096 88.32 6.48366V47.5293L88.2606 47.5547L57.7178 60.5981C57.5349 60.675 57.3384 60.7145 57.14 60.7144Z'
        fill='#F4F4F4'
      />
      <path
        d='M57.14 60.7144C56.9478 60.7146 56.7573 60.6772 56.5793 60.6045L24.9985 47.5007V6.48366C24.999 6.12096 25.1432 5.77323 25.3997 5.51676C25.6562 5.26029 26.0039 5.11602 26.3666 5.1156H86.9519C87.3146 5.11602 87.6624 5.26029 87.9188 5.51676C88.1753 5.77323 88.3196 6.12096 88.32 6.48366V47.5293L88.2606 47.5547L57.7178 60.5981C57.5349 60.675 57.3384 60.7145 57.14 60.7144Z'
        fill='url(#paint0_linear_32583_212190)'
      />
      <path
        d='M57.1406 60.8118C56.9356 60.812 56.7324 60.7723 56.5426 60.6948L24.9014 47.5658V6.48347C24.9018 6.09486 25.0564 5.72229 25.3312 5.4475C25.606 5.17271 25.9785 5.01814 26.3671 5.0177H86.9525C87.3411 5.01814 87.7137 5.17271 87.9885 5.4475C88.2633 5.72229 88.4178 6.09486 88.4183 6.48347V47.5937L57.7567 60.6877C57.5616 60.7698 57.3522 60.812 57.1406 60.8118ZM25.2922 47.3047L56.6917 60.3334C56.9846 60.4522 57.3125 60.4503 57.604 60.328L88.0274 47.3356V6.48347C88.0271 6.1985 87.9137 5.92529 87.7122 5.72378C87.5107 5.52227 87.2375 5.40891 86.9525 5.40857H26.3671C26.0822 5.40891 25.809 5.52227 25.6075 5.72378C25.4059 5.92529 25.2926 6.1985 25.2922 6.48347V47.3047Z'
        fill='#3F3D56'
      />
      <path
        d='M110.697 37.8511H110.658L88.2221 47.4314L57.5249 60.5393C57.405 60.5898 57.2762 60.6161 57.1461 60.6168C57.016 60.6174 56.887 60.5924 56.7665 60.5432L25.0961 47.404L2.1088 37.8667L2.07369 37.8511H2.03456C1.67185 37.8515 1.32411 37.9957 1.06764 38.2522C0.811168 38.5087 0.666906 38.8564 0.666504 39.2191V98.6319C0.666907 98.9946 0.811169 99.3423 1.06764 99.5988C1.32411 99.8552 1.67185 99.9995 2.03456 99.9999H110.697C111.06 99.9995 111.408 99.8553 111.664 99.5988C111.921 99.3423 112.065 98.9946 112.065 98.6319V39.2191C112.065 38.8564 111.921 38.5087 111.664 38.2522C111.408 37.9957 111.06 37.8515 110.697 37.8511ZM111.674 98.6319C111.674 98.891 111.571 99.1395 111.388 99.3227C111.205 99.506 110.956 99.6089 110.697 99.609H2.03456C1.77543 99.6089 1.52694 99.506 1.3437 99.3227C1.16047 99.1395 1.05748 98.891 1.05738 98.6319V39.2191C1.05773 38.9664 1.15575 38.7236 1.33095 38.5415C1.50614 38.3594 1.74494 38.2521 1.99744 38.2419L25.0961 47.8262L56.6161 60.9048C56.9573 61.0436 57.3397 61.0415 57.6793 60.8989L88.2221 47.8555L110.736 38.2419C110.989 38.2528 111.227 38.3605 111.401 38.5425C111.576 38.7245 111.674 38.9668 111.674 39.2191V98.6319Z'
        fill='#3F3D56'
      />
      <path
        d='M52.2236 20.4477H30.7256C30.3109 20.4477 29.9133 20.2829 29.62 19.9897C29.3268 19.6965 29.1621 19.2988 29.1621 18.8842C29.1621 18.4695 29.3268 18.0718 29.62 17.7786C29.9133 17.4854 30.3109 17.3207 30.7256 17.3207H52.2236C52.6383 17.3207 53.036 17.4854 53.3292 17.7786C53.6224 18.0718 53.7871 18.4695 53.7871 18.8842C53.7871 19.2988 53.6224 19.6965 53.3292 19.9897C53.036 20.2829 52.6383 20.4477 52.2236 20.4477Z'
        fill='#4D92DC'
      />
      <path
        d='M41.0837 13.8029H30.7256C30.3109 13.8029 29.9133 13.6382 29.62 13.345C29.3268 13.0517 29.1621 12.6541 29.1621 12.2394C29.1621 11.8247 29.3268 11.4271 29.62 11.1338C29.9133 10.8406 30.3109 10.6759 30.7256 10.6759H41.0837C41.4984 10.6759 41.8961 10.8406 42.1893 11.1338C42.4825 11.4271 42.6472 11.8247 42.6472 12.2394C42.6472 12.6541 42.4825 13.0517 42.1893 13.345C41.8961 13.6382 41.4984 13.8029 41.0837 13.8029Z'
        fill='#4D92DC'
      />
      <path
        d='M72.7382 36.0827H39.9048C39.4901 36.0827 39.0925 35.918 38.7992 35.6247C38.506 35.3315 38.3413 34.9338 38.3413 34.5192C38.3413 34.1045 38.506 33.7068 38.7992 33.4136C39.0925 33.1204 39.4901 32.9557 39.9048 32.9557H72.7382C73.1528 32.9557 73.5505 33.1204 73.8437 33.4136C74.1369 33.7068 74.3016 34.1045 74.3016 34.5192C74.3016 34.9338 74.1369 35.3315 73.8437 35.6247C73.5505 35.918 73.1528 36.0827 72.7382 36.0827Z'
        fill='#CCCCCC'
      />
      <path
        d='M72.7382 43.3139H39.9048C39.4901 43.3139 39.0925 43.1492 38.7992 42.8559C38.506 42.5627 38.3413 42.165 38.3413 41.7504C38.3413 41.3357 38.506 40.938 38.7992 40.6448C39.0925 40.3516 39.4901 40.1869 39.9048 40.1869H72.7382C73.1528 40.1869 73.5505 40.3516 73.8437 40.6448C74.1369 40.938 74.3016 41.3357 74.3016 41.7504C74.3016 42.165 74.1369 42.5627 73.8437 42.8559C73.5505 43.1492 73.1528 43.3139 72.7382 43.3139Z'
        fill='#CCCCCC'
      />
      <defs>
        <linearGradient
          id='paint0_linear_32583_212190'
          x1='56.6593'
          y1='5.1156'
          x2='56.6593'
          y2='60.7144'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MailDocument;

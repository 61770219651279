import React from 'react';

const Banckruptcy = () => (
    <svg className='icon' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_42273_362740)'>
            <path d='M16.8772 9.97534H13.7292V13.7685H16.8772C17.9229 13.7685 18.7737 12.9177 18.7737 11.872C18.7737 10.8262 17.923 9.97534 16.8772 9.97534Z' fill='#333333' />
            <path d='M25.6049 4.39674C19.7443 -1.46384 10.258 -1.46472 4.39657 4.39674C-1.46408 10.2573 -1.46501 19.7437 4.39657 25.6051C10.2572 31.4657 19.7435 31.4666 25.6049 25.6051C31.4655 19.7446 31.4664 10.2582 25.6049 4.39674ZM16.8777 16.2327H13.7298V17.5232H16.252C16.9323 17.5232 17.4838 18.0746 17.4838 18.7549C17.4838 19.4353 16.9323 19.9867 16.252 19.9867H13.7298V21.2577C13.7298 21.938 13.1783 22.4895 12.498 22.4895C11.8177 22.4895 11.2662 21.938 11.2662 21.2577V19.9867H9.99531C9.315 19.9867 8.76351 19.4353 8.76351 18.7549C8.76351 18.0746 9.315 17.5232 9.99531 17.5232H11.2662V16.2327H9.99531C9.315 16.2327 8.76351 15.6812 8.76351 15.0009C8.76351 14.3206 9.315 13.7691 9.99531 13.7691H11.2662V8.74416C11.2662 8.06386 11.8177 7.51236 12.498 7.51236H16.8777C19.2819 7.51236 21.2378 9.46834 21.2378 11.8725C21.2378 14.2768 19.2819 16.2327 16.8777 16.2327Z' fill='#333333' />
        </g>
        <defs>
        <clipPath id='clip0_42273_362740'>
            <rect width='30' height='30' fill='white' />
        </clipPath>
        </defs>
    </svg>
);

export default Banckruptcy

const MailWithTick = () => (
  <svg
    className='icon icon--mail-with-tick'
    width='31'
    height='31'
    viewBox='0 0 31 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.79762 0C2.15524 0 0 2.15524 0 4.79762V18.8214C0 21.4638 2.15524 23.619 4.79762 23.619H13.3967C13.3229 23.1393 13.2857 22.6448 13.2857 22.1429C13.2857 21.8919 13.2937 21.6483 13.3232 21.4048H4.79762C3.3731 21.4048 2.21429 20.246 2.21429 18.8214V8.13058L14.2357 14.6293C14.5641 14.8066 14.9597 14.8066 15.2881 14.6293L27.3095 8.13058V13.6331C28.1214 14.0539 28.8669 14.5928 29.5238 15.2275V4.79762C29.5238 2.15524 27.3686 0 24.7262 0H4.79762ZM4.79762 2.21429H24.7262C26.1507 2.21429 27.3095 3.37309 27.3095 4.79762V5.61356L14.7619 12.3962L2.21429 5.61356V4.79762C2.21429 3.3731 3.37309 2.21429 4.79762 2.21429ZM22.881 14.0238C18.397 14.0238 14.7619 17.6589 14.7619 22.1429C14.7619 26.6268 18.397 30.2619 22.881 30.2619C27.3649 30.2619 31 26.6268 31 22.1429C31 17.6589 27.3649 14.0238 22.881 14.0238ZM27.3095 18.4524C27.4983 18.4524 27.6871 18.5243 27.8314 18.6686C28.12 18.9572 28.12 19.4237 27.8314 19.7123L21.9266 25.6171C21.7827 25.761 21.5937 25.8333 21.4048 25.8333C21.2158 25.8333 21.0268 25.761 20.8829 25.6171L17.9305 22.6647C17.6419 22.3761 17.6419 21.9096 17.9305 21.621C18.2191 21.3324 18.6856 21.3324 18.9742 21.621L21.4048 24.0515L26.7877 18.6686C26.932 18.5243 27.1208 18.4524 27.3095 18.4524L27.3095 18.4524Z'
      fill='#4D92DC'
    />
  </svg>
);

export default MailWithTick;
